import { createApp, defineAsyncComponent } from "vue";
import App from "./App.vue";
import router from "./router";
import { store } from "./store";

import { Quasar, Notify } from "quasar";
import "quasar/src/css/index.sass";

import "@quasar/extras/material-icons/material-icons.css";

// import "ag-grid-community";
// import "ag-grid-enterprise";
// import "@ag-grid-enterprise/charts";
import { LicenseManager } from "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import "ag-charts-community";

LicenseManager.setLicenseKey(
    "CompanyName=A MOT-98 Kereskedelmi s Szolgltat Korltolt Felelssg T,LicensedGroup=TouchGameTable,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-025393,ExpiryDate=1_February_2023_[v2]_MTY3NTIwOTYwMDAwMA==29c20530b314a3ba4c24eeea6b31527c"
);

const app = createApp(App);

app.use(store);
app.use(router);
app.use(Quasar, {
    config: {
        framework: {
            iconSet: "material-icons",
        },
    },
    plugins: { Notify },
});

app.mount("#app");
