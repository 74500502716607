<template></template>
<script lang="ts">
import { defineComponent, onMounted, reactive, computed } from "vue";
export default defineComponent({
    props: {},
    components: {},
    setup(props, { attrs, slots, emit }) {
        const state = reactive({});
        return {
            state,
        };
    },
});
</script>
<style></style>
