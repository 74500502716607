<template>
    <AgCharts :options="chartOptions" style="height: 300px"></AgCharts>
</template>

<script setup lang="ts">
import { ref, Ref, defineProps, watch, toRef, onMounted } from "vue";
import { utils } from "@/tools/utils";
import { AgCartesianChartOptions } from "ag-charts-community";
import { AgCharts } from "ag-charts-vue3";

export type T_GameStatResponse = {
    gameName: string;
    gameTime: number;
};

type T_Props = {
    gameStatData: T_GameStatResponse[];
};
const props = defineProps<T_Props>();
const gameStatData = toRef(props, "gameStatData");

const chartOptions: Ref<AgCartesianChartOptions> = ref({});

watch(gameStatData, (newValue: T_GameStatResponse[]) => {
    setChart(gameStatData.value);
});

function setChart(statResponse: T_GameStatResponse[]) {
    const data = getChartData(statResponse);
    chartOptions.value = {
        data,
        // container: document.getElementById("chart"),
        // autoSize: true,
        series: [
            {
                type: "bar",
                xKey: "gameName",
                yKey: "gameTime",

                // tooltip: {
                //     renderer: function (params) {
                //         return {
                //             title: params.yName,
                //             content: utils.secToHour(params.yValue) + "h",
                //         };
                //     },
                // },
            },
        ],
        axes: [
            {
                type: "category",
                position: "bottom",
                label: {
                    rotation: 30,
                },
            },
            {
                type: "number",
                position: "left",
                label: {
                    formatter: function (params) {
                        return utils.secToHour(params.value) + "h";
                    },
                },
            },
        ],
        legend: {
            enabled: false,
            spacing: 40,
            position: "bottom",
        },
    };

    function getChartData(statResponse: T_GameStatResponse[]) {
        const sorted = statResponse.sort((a: T_GameStatResponse, b: T_GameStatResponse) => {
            if (a.gameTime == b.gameTime) return 0;
            return a.gameTime < b.gameTime ? 1 : -1;
        });
        return sorted;
    }
}
</script>
<style></style>
