/**
 * Created by janos on 2017.03.01..
 */

import { createWebHistory, createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";

import MainLayout from "./layouts/MainLayout.vue";
import CenterLayout from "./layouts/CenterLayout.vue";
import WordSearch from "./pages/WordSearch/WordSearch.vue";
import FunHistory from "./pages/FunHistory/FunHistory.vue";
import PartnerStat from "./pages/Statistics/Partner/PartnerStat.vue";
import PartnersTotalStat from "./pages/Statistics/PartnersTotal/TotalStat.vue";
import PartnersTotalStatGrid from "./pages/Statistics/PartnersTotal/TotalGrid.vue";
import DailyPlayingTime from "./pages/Statistics/PartnersDaily/DailyPlayingTime.vue";
import HourlyPlayingTime from "./pages/Statistics/PartnersHourly/HourlyPlayingTime.vue";
import PartnersLineChart from "./pages/Statistics/PartnersLineChart/Main.vue";
import TotalGameStat from "./pages/Statistics/GameStat/TotalGameStat.vue";
import Users from "./pages/Users/Users.vue";
import PartnerTable from "./pages/Partner/PartnerTable.vue";
import Table from "./pages/PartnerTables/Table.vue";
import Tables from "./pages/Tables/Tables.vue";
import HotelsHungary from "./pages/HotelsHungary/HotelsHungary.vue";
import HotelsAustria from "./pages/HotelsAustria/HotelsAustria.vue";
import Monitor from "./pages/Monitor/Monitor.vue";
import Info from "./pages/Info/Info.vue";
import Login from "./pages/Login.vue";
import PuzzleMarketing from "@/pages/PuzzleMarketing/PuzzleMarketing.vue";
import PrivatePuzzle from "@/pages/PrivatePuzzle/PrivatePuzzle.vue";
import GamesPlayingtimeByPartners from "@/pages/Statistics/GameStat/GamesPlayingtimeByPartners.vue";
import Settings from "@/pages/Settings/Settings.vue";

const routes: RouteRecordRaw[] = [
    {
        path: "/",
        redirect: "/login",
    },
    {
        path: "/partners",
        component: MainLayout,
        children: [{ path: "", name: "partners", component: PartnerTable }],
    },
    {
        path: "/tables",
        component: MainLayout,
        children: [{ path: "", name: "tables", component: Tables }],
    },
    {
        path: "/table/:pid",
        component: MainLayout,
        children: [{ path: "", name: "table", component: Table, props: true }],
    },
    {
        path: "/hotelshungary",
        component: MainLayout,
        children: [{ path: "", name: "hotelshungary", component: HotelsHungary }],
    },
    {
        path: "/hotelsaustria",
        component: MainLayout,
        children: [{ path: "", name: "hotelsaustria", component: HotelsAustria }],
    },
    {
        path: "/wordsearch",
        component: MainLayout,
        children: [{ path: "", name: "wordsearch", component: WordSearch }],
    },
    {
        path: "/funhistory",
        component: MainLayout,
        children: [{ path: "", name: "funhistory", component: FunHistory }],
    },
    {
        path: "/partnerstat",
        component: MainLayout,
        children: [{ path: "", name: "partnerstat", component: PartnerStat }],
    },
    {
        path: "/partnerstotalstat",
        component: MainLayout,
        children: [{ path: "", name: "partnerstotalstat", component: PartnersTotalStat }],
    },
    {
        path: "/partnerstotalstatgrid",
        component: MainLayout,
        children: [{ path: "", name: "partnerstotalstatgrid", component: PartnersTotalStatGrid }],
    },
    {
        path: "/dailyplayingtime",
        component: MainLayout,
        children: [{ path: "", name: "dailyplayingtime", component: DailyPlayingTime }],
    },
    {
        path: "/hourlyplayingtime",
        component: MainLayout,
        children: [{ path: "", name: "hourlyplayingtime", component: HourlyPlayingTime }],
    },
    {
        path: "/gamesplayingtimebypartners",
        component: MainLayout,
        children: [{ path: "", name: "gamesplayingtimebypartners", component: GamesPlayingtimeByPartners }],
    },
    {
        path: "/totalgamestat",
        component: MainLayout,
        children: [{ path: "", name: "totalgamestat", component: TotalGameStat }],
    },
    {
        path: "/partnerslinechart",
        component: MainLayout,
        children: [{ path: "", name: "partnerslinechart", component: PartnersLineChart }],
    },
    {
        path: "/users",
        component: MainLayout,
        children: [{ path: "", name: "users", component: Users }],
    },
    {
        path: "/monitor",
        component: MainLayout,
        children: [{ path: "", name: "monitor", component: Monitor }],
    },
    {
        path: "/puzzlemarketing",
        component: MainLayout,
        children: [{ path: "", name: "puzzlemarketing", component: PuzzleMarketing }],
    },
    {
        path: "/puzzle",
        component: MainLayout,
        children: [{ path: "", name: "puzzle", component: PrivatePuzzle }],
    },
    {
        path: "/info",
        component: MainLayout,
        children: [{ path: "", name: "info", component: Info }],
    },

    {
        path: "/settings",
        component: MainLayout,
        children: [{ path: "", name: "settings", component: Settings }],
    },
    {
        path: "/login",
        component: CenterLayout,
        children: [{ path: "", name: "login", component: Login }],
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;

// router.beforeEach((to, from, next) => {
//     console.log(from.path, to.path);

//     next();
// });
