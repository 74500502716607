<template>
    <q-page class="q-pa-md">
        <div class="row items-end justify-between">
            <!-- <q-input v-model="quickFilter" @input="setQuickFilter" placeholder="Filter" /> -->
            <!-- <q-input v-model="state.quickFilter" @update:model-value="setQuickFilter" placeholder="Filter" /> -->
            <!-- <div class="q-gutter-md">
                <q-btn color="primary" label="Load" @click="onLoad()" />
                <q-btn color="primary" label="Add" @click="addRow()" />
            </div> -->
            <LangSelector v-on:langChanged="langChanged" v-on:quickFilter="setQuickFilter" v-on:addRow="addRow">
                <template #buttons> <q-btn color="primary" label="Load" @click="load()" /> </template
            ></LangSelector>
        </div>

        <ag-grid-vue style="width: 100%; height: 700px; margin: 20px 0" :gridOptions="grid" class="ag-theme-balham" id="grid"></ag-grid-vue>
    </q-page>
</template>
<script setup lang="ts">
import { AgGridVue } from "ag-grid-vue3";
import { CellValueChangedEvent, Column, GridOptions, RowNode } from "ag-grid-community";
import { gridConfig } from "./grid-config";
import { onMounted, reactive, computed } from "vue";
import { useQuasar } from "quasar";
import { useRouter } from "vue-router";
import { debounce } from "lodash";
import { store } from "@/store";
import LangSelector from "@/components/LangSelector.vue";
import useFetch from "@/tools/fetch";

type TRow = {
    id: string;
    source: string;
    target: string;
    date: number;
};

const $q = useQuasar();
const headers = new Headers({ "content-type": "application/json" });
const url = store.getters.getUrl("/game/funhistory");
const router = useRouter();

const debounced = debounce(addRow, { maxWait: 1000 });
const grid: GridOptions = gridConfig();
grid.context.actionCallback = actionCallback;
// grid.onGridReady = load;
grid.onCellValueChanged = (event: CellValueChangedEvent) => {
    console.log(event);
    save(event.data, event.node, event.column.getColId());
};

const TESTID = "99999";

const langPair = { source: "hu", target: "en" };

onMounted(() => {
    console.log("onMounted");

    document.addEventListener("keydown", (e) => {
        // console.log(e.key, e.altKey);
        if (e.altKey && e.key == "a") {
            debounced();
        }
    });

    // state.grid = gridConfig(this);
});

grid.onGridReady = () => {
    // load();
};

function setQuickFilter(value: string) {
    if (grid.api == null) return;
    grid.api.setQuickFilter(value);
}

async function save(data: TRow, node: RowNode, colId: string) {
    const method = data.id === TESTID ? "POST" : "PUT";
    let empty = true;
    for (let key of Object.keys(data)) {
        if (key == "id") continue;
        if (data[key]) {
            empty = false;
            break;
        }
    }
    if (empty) return;

    if (data.date == 9999) {
        $q.notify({ position: "top", type: "negative", message: "Invalid date." });
        return;
    }

    const lang = langPair[colId];
    const resp = await useFetch(url, { method, body: { question: { [lang]: data[colId] }, id: data.id, date: Number(data.date) } });
    if (resp) {
        if (method == "POST") {
            if (grid.api == null) return;

            grid.api.applyTransaction({
                remove: [{ id: TESTID }],
            });
            grid.api.applyTransaction({
                add: [{ id: resp.id, [colId]: resp.question[lang] }],
                addIndex: 0,
            });
            if (colId == "source") {
                grid.api.startEditingCell({ rowIndex: 0, colKey: "target" });
            } else if (colId == "date" && !data.source) {
                grid.api.startEditingCell({ rowIndex: 0, colKey: "source" });
            }
        } else {
            // grid.api?.applyTransaction({ update: [{ id: resp.id, source: resp.question[langPair.source], target: resp.question[langPair.target] }] });
            // node.setData({ id: resp._id, source: resp[langPair.source], target: resp[langPair.target] });
        }
        node.setSelected(false);
    }
}
function addRow() {
    // const node = grid.api.getRowNode("0");
    // if (node) return;

    try {
        grid.columnApi?.applyColumnState({ state: [{ colId: "date", sort: null }] });

        try {
            grid.api?.applyTransaction({
                remove: [{ id: TESTID }],
            });
        } catch (e) {}
        grid.api?.applyTransaction({
            add: [{ id: TESTID, source: null, target: null, date: 9999 }],
            addIndex: 0,
        });
        grid.api?.startEditingCell({ rowIndex: 0, colKey: "date" });
    } catch (e) {}
}

function langChanged(value: { source: string; target: string }) {
    langPair.source = value.source;
    langPair.target = value.target;
}

async function load(limit = 0) {
    if (langPair.source == "") {
        $q.notify({ position: "top", type: "negative", message: "Source language is missing." });
    }
    const resp = await useFetch(`${url}/?source=${langPair.source}&target=${langPair.target}&limit=${limit}`, { method: "GET" });
    if (resp) {
        if (grid.api == null) return;
        const gridData = resp.map((item: { _id: string; [s: string]: string }) => {
            return { id: item.id, source: item.question[langPair.source], target: item.question[langPair.target], date: item.date };
        });
        grid.api.setRowData(gridData);
    }
}

async function actionCallback(action: string, node: RowNode) {
    // console.log(action, node);
    switch (action) {
        case "delete":
            if (node.data.id === TESTID) {
                grid.api?.applyTransaction({ remove: [{ id: node.data.id }] });
            } else {
                const resp = await useFetch(`${url}/${node.data.id}`, { method: "DELETE" });
                if (resp) {
                    grid.api?.applyTransaction({ remove: [{ id: node.data.id }] });
                }
            }
            break;
    }
}
</script>
<style></style>
