import GameRenderer from "@/components/GameRenderer.vue";
import Checkbox from "@/components/checkbox.js";
import { GridOptions, ColDef, ValueSetterParams, ValueGetterParams, ValueFormatterParams } from "ag-grid-community";
import { utils } from "@/tools/utils";

export function gridOptions(): GridOptions {
    return {
        pagination: true,
        paginationPageSize: 500,
        context: {
            actionCallback: null,
            actionIcons: [
                {
                    icon: "edit",
                    tooltip: "Edit",
                },
                {
                    icon: "save",
                    tooltip: "Save",
                },
                {
                    icon: "delete",
                    tooltip: "Delete",
                },
            ],
        },
        rowSelection: "multiple",
        defaultColDef: {
            // allow every column to be aggregated
            enableValue: true,
            // allow every column to be grouped
            enableRowGroup: true,
            // allow every column to be pivoted
            enablePivot: true,
            resizable: true,
            sortable: true,
            filter: true,
        },
        sideBar: {
            toolPanels: [
                {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel",
                },
                {
                    id: "filters",
                    labelDefault: "Filters",
                    labelKey: "filters",
                    iconKey: "filter",
                    toolPanel: "agFiltersToolPanel",
                },
            ],
        },

        onGridReady: () => {},
        components: {
            checkbox: Checkbox,
            gameRenderer: GameRenderer,
        },
        getRowId: function (params) {
            return params.data.id;
        },
        rowData: [],
        columnDefs: columDefs(),
        enableCellChangeFlash: true,
        // refreshCells: true
    };
}

function columDefs(): ColDef[] {
    return [
        {
            headerName: "Name",
            field: "partnerName",
            width: 180,
            filter: "text",
            sort: "asc",
            editable: false,
        },
        // {
        //     headerName: "City",
        //     field: "city",
        //     width: 180,
        //     editable: false,
        // },
        {
            headerName: "Playing Time",
            field: "gameTime",
            width: 180,
            editable: false,
            valueFormatter: (params: ValueFormatterParams) => {
                return utils.formatGameTime(params.data.gameTime);
            },
        },
    ];
}
