<template>
    <q-page class="q-pa-md">
        <div class="row items-end justify-between">
            <q-input v-model="state.quickFilter" @update:model-value="setQuickFilter" placeholder="Filter" />
            <q-btn color="primary items-end" label="Excel" @click="exportExcel()" />
        </div>
        <AgGridVue style="width: 100%; height: 700px; margin: 20px 0" class="ag-theme-balham" :gridOptions="state.grid"></AgGridVue>
    </q-page>
</template>

<script setup lang="ts">
import { onMounted, reactive, computed, ref, onBeforeMount } from "vue";
import { AgGridVue } from "ag-grid-vue3";
import { GridOptions } from "ag-grid-community";
import { gridOptions } from "./grid-config";
import { store } from "@/store";
import { useQuasar } from "quasar";
import useFetch from "@/tools/fetch";

type T_State = {
    grid: GridOptions;
    quickFilter: string;
};

type T_GridRow = {
    id: string;
    name: string;
    city: string;
    address: string;
    star: number;
    loc: { type: string; coordinates: number[] };
};

const grid: GridOptions = gridOptions();
const $q = useQuasar();

const url = store.getters.getUrl();

const state: T_State = reactive({ grid: grid, quickFilter: "" }) as T_State;

grid.onGridReady = () => {
    grid.rowSelection = "single";
    setQuickFilter();
    load();
};

function setQuickFilter() {
    grid.api?.setQuickFilter(state.quickFilter);
}

async function load() {
    grid.rowData = [];
    const hotels: T_GridRow[] = await useFetch(`${url}/hotelshungary`, { method: "GET" });
    if (hotels) {
        grid.api?.applyTransaction({ add: hotels });
    } else {
    }
}

function exportExcel() {
    grid.api?.exportDataAsExcel();
}
</script>
<style></style>
