<template>
    <q-page>
        <StatHeader @loadData="loadData" :multiple="true" :partnersVisible="true" :totalTime="totalTime"></StatHeader>
        <div class="col">
            <PlayingTime :playingTimeData="playingTimeData"></PlayingTime>
        </div>
    </q-page>
</template>

<script lang="ts" setup>
import { ref, Ref } from "vue";

import { store } from "@/store";
import StatHeader from "../StatHeaderRooms.vue";

import PlayingTime, { T_PlayingTimeResponse } from "./PlayingTime.vue";

import useFetch from "@/tools/fetch";
import { utils } from "@/tools/utils";

const url = store.getters.getUrl("/stat/partners");
const totalTime = ref("0");

const playingTimeData: Ref<T_PlayingTimeResponse[]> = ref([]);

async function loadData(pids: string | string[], start: string, stop: string, rooms: number[]) {
    console.log(pids, start, stop);

    playingTimeData.value = await useFetch(`${url}/totalplayingtime`, { method: "POST", body: { pids, start, stop, rooms } });
    let total = 0;
    for (const record of playingTimeData.value) {
        total += record.gameTime;
    }
    totalTime.value = utils.formatGameTime(total);
}
</script>
<style></style>
