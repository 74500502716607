<template>
    <q-card class="q-px-md" style="max-width: 100%; overflow: hidden">
        <q-card-section class="row items-center">
            <span>Fill up credit</span>
        </q-card-section>
        <q-card-section>
            <q-input dense type="text" name="credit" v-model="timeBudgetInHours" label="Time budget" hint="" disable style="width: 100px" />

            <q-form @reset="onReset" class="q-gutter-md row items-start" style="width: 100%" @submit.prevent="onSubmit" method="post" ref="creditFillUpForm">
                <q-input class="col" dense type="number" name="credit" v-model="credit" label="Credit" hint="" lazy-rules />

                <q-select class="col" v-model="currency" :options="currencyList" label="Currency" style="margin-bottom: 20px" dense />

                <q-input
                    class="col"
                    dense
                    type="number"
                    name="time"
                    v-model="time"
                    label="Time in hours"
                    lazy-rules
                    :rules="[(val) => (val !== null && val !== '') || 'Please add some hours', (val) => val > 0 || 'Please add valid time']"
                />
                <q-input
                    class="col"
                    dense
                    type="text"
                    name="date"
                    v-model="date"
                    label="Date"
                    lazy-rules
                    :rules="[(val) => (val !== null && val !== '') || 'Please type valid date']"
                />

                <div class="row">
                    <q-btn class="col" label="Add" type="submit" color="primary" />
                    <q-btn clas="col" label="Reset" type="reset" color="primary" flat class="q-ml-sm" />
                </div>
            </q-form>
            <q-card-section style="max-height: 70vh; overflow-y: scroll">
                <q-list>
                    <q-item v-for="(item, index) of creditFillList">
                        <q-item-section>
                            <q-item-label>{{ `Date: ${item.timestamp}` }}</q-item-label>
                            <q-item-label caption lines="1">{{ `Time: ${item.time}h` }}</q-item-label>
                            <q-item-label caption lines="1">{{ `Credit: ${item.credit}${item.currency}` }}</q-item-label>
                        </q-item-section>

                        <q-item-section side top>
                            <q-icon name="delete" color="red" @click="onDelete(index)" />
                        </q-item-section>
                    </q-item>
                    <q-separator spaced inset />
                </q-list>
            </q-card-section>
        </q-card-section>
    </q-card>
</template>
<script setup lang="ts">
import { onMounted, reactive, computed, ref } from "vue";
import { useQuasar, QForm } from "quasar";
import { IPartner } from "../model";
import { store } from "@/store";
import useFetch from "@/tools/fetch";
import { secToHour } from "@/components/utils";

const props = defineProps<{
    partner: IPartner;
}>();

interface ICreditFill {
    timestamp: string;
    credit: number;
    currency: string;
    time: number;
}

interface ICreditFillResp {
    creditRefillHistory: ICreditFill[];
    timeBudget: number;
}

const url = store.state.baseUrl + "/partner/creditfill";
const $q = useQuasar();
const currencyList = ["Ft", "€"];
const credit = ref(0);
const time = ref(0);
const date = ref(getCurrDate());
const currency = ref("Ft");
const creditFillUpForm = ref<QForm | null>(null);
const timeBudget = ref(0);
const creditFillList = ref<ICreditFill[]>([]);

function getCurrDate(): string {
    return new Date(Date.now()).toISOString().substring(0, 10);
}

function onReset() {
    creditFillUpForm.value?.resetValidation();
}

const timeBudgetInHours = computed(() => {
    return secToHour(timeBudget.value);
});

const action = computed(() => {
    return `${url}/${props.partner.id}`;
});

async function onSubmit() {
    const body: ICreditFill = {
        timestamp: date.value.replace("T", " "),
        credit: Number(credit.value),
        time: Number(time.value),
        currency: currency.value,
    };
    const resp = (await useFetch(`${url}/${props.partner.id}`, { method: "POST", body })) as ICreditFillResp;
    if (resp) {
        setServerResponse(resp);
    }
}

async function onDelete(index) {
    const newList = creditFillList.value.toSpliced(index, 1);
    const resp = (await useFetch(`${url}/${props.partner.id}`, { method: "PUT", body: newList })) as ICreditFillResp;
    if (resp) {
        setServerResponse(resp);
    }
}

function setServerResponse(resp: ICreditFillResp) {
    timeBudget.value = resp.timeBudget;
    creditFillList.value = resp.creditRefillHistory;
}

onMounted(async () => {
    const resp = (await useFetch(`${url}/${props.partner.id}`, { method: "GET" })) as ICreditFillResp;
    if (resp) {
        setServerResponse(resp);
    }
});
</script>
<style></style>
