<template>
    <q-page class="q-pa-md">
        <div class="row items-end justify-between">
            <q-input v-model="state.quickFilter" @update:model-value="setQuickFilter" placeholder="Filter" />
        </div>
        <AgGridVue style="width: 100%; height: 700px; margin: 20px 0" class="ag-theme-balham" :gridOptions="state.grid"></AgGridVue>
    </q-page>
</template>

<script setup lang="ts">
import { onMounted, reactive, computed, ref, onBeforeMount } from "vue";
import { AgGridVue } from "ag-grid-vue3";
import { CellValueChangedEvent, GridOptions, GridSerializer, RowNode, RowClickedEvent, CellClickedEvent } from "ag-grid-community";
import { gridOptions } from "./grid-config";
import { store, T_Game } from "@/store";
import { useQuasar } from "quasar";
import useFetch from "@/tools/fetch";

type T_State = {
    grid: GridOptions;
    quickFilter: string;
};

type T_GridRow = {
    id: string;
    name: string;
    email: string;
    hotel: string;
    question: string[];
    date: string;
};

type T_ServerResponse = {
    id: string;
    firstname: string;
    lastname: string;
    email: string;
    hotel: string;
    question: string[];
    date: string;
};

const grid: GridOptions = gridOptions();

const $q = useQuasar();

const url = store.getters.getUrl();

const state: T_State = reactive({ grid: grid, quickFilter: "", dialogVisible: false, dialogView: null }) as T_State;

function setQuickFilter() {
    grid.api?.setQuickFilter(state.quickFilter);
}

grid.onGridReady = () => {
    grid.api?.exportDataAsExcel();
    load();
};

async function load() {
    grid.rowData = [];
    const infos: T_ServerResponse[] = (await useFetch(`${url}/infoquery`, { method: "GET" })) as T_ServerResponse[];
    if (infos) {
        const rows = infos.map((item) => {
            const date = item.date.match(/\d{4}-\d{2}-\d{2}/);
            return {
                id: item.id,
                name: `${item.lastname} ${item.firstname}`,
                email: item.email,
                hotel: item.hotel,
                question: item.question,
                date: date?.[0],
            };
        });
        grid.api?.applyTransaction({ add: rows });
    } else {
    }
}
</script>
