<template>
    <q-page>
        <StatHeader @loadData="loadData" :multiple="false" :partners-visible="false"></StatHeader>
        <div class="col">
            <GameStat :gameStatData="gameStatData"></GameStat>
        </div>
    </q-page>
</template>

<script lang="ts" setup>
import { ref, Ref, onMounted } from "vue";

import { store } from "@/store";
import StatHeader from "../StatHeader.vue";

import GameStat, { T_GameStatResponse } from "@/components/GameStat.vue";

import useFetch from "@/tools/fetch";

const url = store.getters.getUrl("/stat");

const gameStatData: Ref<T_GameStatResponse[]> = ref([]);

async function loadData(pid: string | string[], start: string, stop: string) {
    // console.log(pid, start, stop);
    gameStatData.value = await useFetch(`${url}/totalgamestat`, { method: "POST", body: { start, stop } });
}
</script>
<style></style>
