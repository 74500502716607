<template>
    <q-page class="q-pa-md">
        <div class="row items-end q-gutter-lg justify-between">
            <div class="col-3 row items-end q-gutter-lg">
                <q-input v-model="state.quickFilter" @update:model-value="setQuickFilter" placeholder="Filter" />

                <q-btn color="primary" label="Refresh" @click="refreshMonitor()" />
            </div>
            <div class="col row items-end q-gutter-lg">
                <q-select
                    class="col-2"
                    v-model="state.lobbyCommand"
                    @input-value="setLobbyCommand"
                    :options="LOBBY_COMMANDS"
                    label="Lobby command"
                    fill-input
                    use-input
                    hide-selected
                    input-debounce="0"
                    clearable
                />
                <q-select
                    class="col-3"
                    v-model="state.tableCommand"
                    @input-value="setTableCommand"
                    :options="TABLE_COMMANDS"
                    label="Table command"
                    fill-input
                    use-input
                    hide-selected
                    input-debounce="0"
                    clearable
                />
                <q-input class="col-4" v-model="state.shellCommand" placeholder="Shell command" clearable />
                <q-btn class="col" color="primary" label="Send" @click="sendCommand()" />
            </div>
        </div>
        <AgGridVue style="width: 100%; height: 700px; margin: 20px 0" class="ag-theme-balham" :gridOptions="state.grid"></AgGridVue>
        <q-dialog
            stye="max-height: 80vh; "
            v-model="state.terminalVisible"
            transition-show="slide-up"
            transition-hide="slide-down"
            v-on:close="state.terminalVisible = false"
        >
            <component :is="Terminal" :tid="getSlecetedTids()[0]"></component>
        </q-dialog>
    </q-page>
</template>
<script setup lang="ts">
import { AgGridVue } from "ag-grid-vue3";
import { CellValueChangedEvent, GridOptions, RowClickedEvent } from "ag-grid-community";
import { gridOptions } from "./grid-config";
import { onMounted, reactive } from "vue";
import { store } from "@/store";
import { useQuasar } from "quasar";
import useFetch from "@/tools/fetch";
import Terminal from "./Terminal.vue";

const testData = [
    { tid: "123456", tableName: "Blue", partnerName: "karos", cit: "Zala", logEnabled: false, updateEnabled: false, status: "lobby" },
    { tid: "245699", tableName: "Blue", partnerName: "karos", cit: "Zala", logEnabled: false, updateEnabled: false, status: "lobby" },
    { tid: "345985", tableName: "Blue", partnerName: "karos", cit: "Zala", logEnabled: false, updateEnabled: false, status: "lobby" },
    { tid: "456678", tableName: "Blue", partnerName: "karos", cit: "Zala", logEnabled: false, updateEnabled: false, status: "lobby" },
];

type T_State = {
    grid: GridOptions;
    quickFilter: string;
    shellCommand: string;
    tableCommand: string;
    lobbyCommand: string;
    terminalVisible: boolean;
};

type T_MonitorData = {
    tid: string;
    status: string;
    port: number;
};

type T_TableData = {
    tid: string;
    tableName: string;
    partnerName: string;
    city: string;
    logEnabled: boolean;
    updateEnabled: boolean;
};

type T_TableMap = {
    [key: string]: T_TableData;
};
type T_GridData = T_MonitorData | T_TableData;

const TABLE_COMMANDS = [
    "checkUpdate",
    "restart",
    "restartBrowser",
    "touchGuard:",
    "download:",
    "install:",
    "restartRunner",
    "screenshot",
    "loginEnabled",
    "loginDisabled",
];
const LOBBY_COMMANDS = ["lobbyReload", "restart"];
const grid: GridOptions = gridOptions();
grid.context.actionCallback = actionClick;
const $q = useQuasar();

const url = store.getters.getUrl();

const state: T_State = reactive({ grid: grid, quickFilter: "", shellCommand: "", tableCommand: "", lobbyCommand: "", terminalVisible: false }) as T_State;
let tableMap: T_TableMap;

grid.onGridReady = async () => {
    // grid.rowSelection = "single";
    grid.onRowClicked = (event: RowClickedEvent) => {};
    grid.onCellValueChanged = (event: CellValueChangedEvent) => {
        let field = event.colDef.field as string;

        console.log("Cell changed: ", field, event.newValue);

        let saveField = "";
        if (field.startsWith("flags")) {
            saveField = "flags";
        }

        switch (saveField) {
            default:
                const value = event.data.flags;
                saveSingleItem(event.data.tid, saveField, value);
        }

        let cmd = "";
        switch (field) {
            case "flags.logEnabled":
                cmd = event.newValue ? "logEnable" : "logDisable";

                break;
            case "flags.touchGuardEnabled":
                cmd = event.newValue ? "touchGuard:1" : "touchGuard:0";
                break;
        }
        if (cmd) {
            sendTableCommand(cmd);
        }

        // save(event.data);
    };
    // grid.onCellMouseOver = cellMouseOver;
    // grid.api?.applyTransaction({ add: testData });
    await loadTables();
};
function setQuickFilter() {
    if (grid.api == null) return;
    grid.api.setQuickFilter(state.quickFilter);
}

onMounted(() => {
    // loadTables();
});

async function saveSingleItem(tid: string, key: string, value: any) {
    // console.log(pid, key, value);
    await useFetch(`${url}/table`, { method: "PUT", body: { tid, key, value } });
}

async function sendCommand() {
    const tids = getSlecetedTids();

    if (state.shellCommand) {
        const message = { cmd: "runCommand", value: state.shellCommand };
        const resp = await useFetch(`${url}/table/command`, { method: "POST", body: { cmd: "shell", message, tids: tids } });
    }

    if (state.tableCommand) {
        sendTableCommand(state.tableCommand);
    }
    if (state.lobbyCommand) {
        const parts = state.lobbyCommand.split(":");

        const message = { cmd: parts[0], value: "" };
        if (parts.length > 1) {
            message.value = parts[1];
        }
        const resp = await useFetch(`${url}/table/command`, { method: "POST", body: { cmd: "lobby", message, tids: tids } });
    }
}

async function sendTableCommand(mess: string) {
    const tids = getSlecetedTids();

    const parts = mess.split(":");

    const message = { cmd: parts[0], value: "" };
    if (parts.length > 1) {
        message.value = parts[1];
    }
    const resp = await useFetch(`${url}/table/command`, { method: "POST", body: { cmd: "table", message, tids: tids } });
}

function getSlecetedTids(): string[] {
    const rows: T_MonitorData[] = grid.api?.getSelectedRows() as T_MonitorData[];
    return rows.map((item: T_MonitorData) => {
        return item.tid;
    });
}

async function loadTables() {
    const data: T_TableData[] = await useFetch(`${url}/monitor/tables`, { method: "GET" });
    if (data) {
        tableMap = {};
        for (const item of data) {
            tableMap[item.tid] = item;
        }
        await refreshMonitor();
    }
}

async function refreshMonitor() {
    if (tableMap === undefined) {
        $q.notify({ position: "top", type: "negative", message: "Table data is missing" });
        return;
    }

    grid.api?.setRowData([]);
    const response: T_MonitorData[] = await useFetch(`${url}/monitor/start`, { method: "GET" });
    if (response) {
        const gridData: T_GridData[] = [];
        for (const item of response) {
            gridData.push(Object.assign(item, tableMap[item.tid]));
        }
        grid.api?.applyTransaction({ add: gridData });
    }
}

async function actionClick(event: string, data: any) {
    console.log(event, data);

    switch (event) {
        case "terminal":
            state.terminalVisible = true;
            // dialog.value = ActionSelector;

            break;
    }
}

function setTableCommand(newValue: string) {
    state.tableCommand = newValue;
}

function setLobbyCommand(newValue: string) {
    state.lobbyCommand = newValue;
}
</script>
<style></style>
