export namespace utils {
    export function secToMin(sec: number): { min: number; sec: number } {
        const minutes = Math.floor(sec / 60);
        const leftSec = sec % 60;
        return { min: minutes, sec: leftSec };
    }

    export function secToHour(sec): string {
        if (typeof sec == "undefined") {
            sec = 0;
        }
        let hours = Math.floor(sec / 3600);
        let minutes = String(Math.floor((sec - hours * 3600) / 60));
        let h = String(hours);
        return `${h.padStart(2, "0")}:${minutes.padStart(2, "0")}`;
    }

    export function formatDate(d: Date | number, time = false): string {
        let date;
        if (typeof d == "number") {
            let tz = -1 * new Date().getTimezoneOffset();
            date = new Date(d * 1000);
        } else {
            date = d;
        }
        let year = date.getFullYear();
        let month = `${date.getMonth() + 1}`;
        let day = `${date.getDate()}`;
        let hours = `${date.getHours()}`;
        let minutes = `${date.getMinutes()}`;
        let res = `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
        if (time) {
            res += ` ${hours.padStart(2, "0")}:${minutes.padStart(2, "0")}`;
        }
        return res;
    }

    export function formatGameTimeMinutes(d) {
        if (d == 0) {
            return "00:00";
        }

        let minutes = String(Math.floor(d / 60));
        let seconds = String(Math.floor(d % 60));

        return `${minutes.padStart(2, "0")}:${seconds.padStart(2, "0")}`;
    }

    // sec to hh:mm
    export function formatGameTime(d: number) {
        if (d == 0) {
            return "00:00";
        }
        let hours = Math.floor(d / 3600);
        let minutes = String(Math.floor((d - hours * 3600) / 60));
        let h = String(hours);
        return `${h.padStart(2, "0")}h:${minutes.padStart(2, "")}min`;
    }

    export function accentedTextFormatter(s): string {
        let r = s.toLowerCase();
        r = r.replace(new RegExp("\\s", "g"), "");
        r = r.replace(new RegExp("[àáâãäå]", "g"), "a");
        r = r.replace(new RegExp("æ", "g"), "ae");
        r = r.replace(new RegExp("ç", "g"), "c");
        r = r.replace(new RegExp("[èéêë]", "g"), "e");
        r = r.replace(new RegExp("[ìíîï]", "g"), "i");
        r = r.replace(new RegExp("ñ", "g"), "n");
        r = r.replace(new RegExp("[òóôõöő]", "g"), "o");
        r = r.replace(new RegExp("œ", "g"), "oe");
        r = r.replace(new RegExp("[ùúûüű]", "g"), "u");
        r = r.replace(new RegExp("[ýÿ]", "g"), "y");
        r = r.replace(new RegExp("\\W", "g"), "");
        return r;
    }

    export const getUrlParameter = (key: string) => {
        return decodeURIComponent(
            window.location.search.replace(
                new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"),
                "$1"
            )
        );
    };
}
