<template>
    <Endpoints :endpoints="settings?.endpoints"></Endpoints>
</template>
<script setup lang="ts">
import { onBeforeMount, reactive, computed, ref } from "vue";
import useFetch from "@/tools/fetch";
import { ISettings } from "./model";
import { store } from "@/store";
import Endpoints from "./Endpoints.vue";

const url = store.getters.getUrl();
const settings = ref<ISettings>();

async function load() {
    const resp: ISettings = await useFetch(`${url}/settings`, { method: "GET" });
    if (resp) {
        settings.value = resp;
    }
}

onBeforeMount(() => {
    load();
});
</script>
<style></style>
