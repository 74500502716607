import { ref } from 'vue';
<template>
    <div>{{ games }}</div>
</template>

<script setup lang="ts">
import { computed, toRef } from "vue";
import { store } from "@/store";

const props = defineProps<{
    params: any;
}>();

let params: any = toRef(props, "params");

const games = computed(() => {
    if (!params.value) {
        return [];
    }
    const gameList = params.value.value.map((game) => {
        return getGameLabel(game);
    });

    return gameList
        .sort((a, b) => {
            if (a == b) return 0;
            return a < b ? -1 : 1;
        })
        .join(",");
});

function getGameLabel(gameId) {
    const _games = store.state.games;
    for (let i = 0; i < _games.length; i++) {
        if (_games[i].value == gameId) {
            return _games[i].label;
        }
    }
}

function refresh(_params) {
    if (params.value !== params.value) {
        params = _params;
    }
    return true;
}
</script>
