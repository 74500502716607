<template>
    <div class="justify-start row">
        <q-icon
            class="action-icon"
            v-bind:style="{ color: item.icon == 'save' ? saveColor : '#7a7a7a' }"
            size="24px"
            v-for="item in icons"
            :name="item.icon"
            :key="item.icon"
            @click="onClick(item.icon)"
            :title="item.tooltip"
        />
    </div>
</template>

<script setup lang="ts">
import { ref, watch, toRef, computed } from "vue";
import { store } from "@/store";

const props = defineProps<{
    params: any;
}>();

let params: any = toRef(props, "params");
let saveColor = ref<string>("#7a7a7a");

const icons = computed(() => {
    return params.value.context.actionIcons;
});

watch(params, (newValue, oldValue) => {
    if (oldValue != newValue) {
        saveColor.value = "red";
    }
});

function refresh(params) {
    saveColor.value = "red";
    return true;
}

function onClick(event: string) {
    console.log(event, params.value);
    params.value.context.actionCallback?.(event, params.value.node);
    if (event == "save") {
        saveColor.value = "#7a7a7a";
    }
}
</script>

<style scoped>
.action-icon {
    color: #7a7a7a;
    margin-right: 10px;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: auto;
    background-color: #555;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;

    /* Position the tooltip text */
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;

    /* Fade in tooltip */
    opacity: 0;
    transition: opacity 0.3s;
}

/* Tooltip arrow */
.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}
</style>
