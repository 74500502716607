<template>
    <q-card class="items-center justify-start" style="height: auto; min-width: 400px; max-width: 80vw">
        <q-card-section class="text-h6 bg-primary text-white">
            <div class="row justify-start">
                <q-btn flat label="Select a target partner" />
            </div>
        </q-card-section>
        <q-card-section class="column q-gutter-md">
            <q-select
                dense
                v-model="targetPartner"
                :options="filteredPartners"
                label="Partners"
                use-input
                hide-selected
                fill-input
                input-debounce="0"
                @filter="filterFn"
                hint="Select a partner"
            />
        </q-card-section>
        <q-card-section class="row">
            <q-btn class="col" color="primary" label="Select" @click="select()" />
        </q-card-section>
    </q-card>
</template>
<script setup lang="ts">
import { store } from "@/store";
import useFetch from "@/tools/fetch";
import { Notify } from "quasar";
import { ref, computed } from "vue";
import { T_Partner } from "@/model/partner";
import { utils } from "../../tools/utils";

interface IPartner {
    label: string;
    value: string;
}

const targetPartner = ref<IPartner>();
const partners = getPartners();
const filteredPartners = ref<IPartner[]>(getPartners());

const emit = defineEmits(["dialogEvent"]);

function getPartners(): IPartner[] {
    return store.state.partners
        .map((p: T_Partner) => {
            return { value: p.id, label: p.partnerName };
        })
        .sort((a: IPartner, b: IPartner) => {
            return ("" + a.label).localeCompare(b.label);
        });
}

function select() {
    emit("dialogEvent", { action: "relocateTable", data: targetPartner.value?.value });
}

function filterFn(val, update, abort) {
    update(() => {
        const needle = val.toLowerCase();
        filteredPartners.value = partners.filter((v) => v.label.toLowerCase().startsWith(needle));
    });
}
</script>
<style></style>
