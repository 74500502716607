<template>
    <q-page class="q-pa-md">
        <div class="row items-end justify-between">
            <!-- <q-input v-model="quickFilter" @input="setQuickFilter" placeholder="Filter" /> -->
            <!-- <q-input v-model="state.quickFilter" @update:model-value="setQuickFilter" placeholder="Filter" /> -->
            <!-- <div class="q-gutter-md">
                <q-btn color="primary" label="Load" @click="onLoad()" />
                <q-btn color="primary" label="Add" @click="addRow()" />
            </div> -->
            <LangSelector v-on:langChanged="langChanged" v-on:quickFilter="setQuickFilter" v-on:addRow="addRow">
                <template #buttons>
                    <div class="row q-gutter-x-md items-end">
                        <q-btn-dropdown color="primary" label="Load">
                            <q-list v-for="item in loadOptions" :key="item.value">
                                <q-item clickable v-close-popup @click="load(item.value)">
                                    <q-item-section>
                                        <q-item-label>{{ item.label }}</q-item-label>
                                    </q-item-section>
                                </q-item>
                            </q-list>
                        </q-btn-dropdown>

                        <q-input v-model="state.wordLength" placeholder="Set word length" :clearable="true" />
                        <q-btn color="primary" label="Load by length" @click="loadByFilter" />
                        <q-input v-model="state.wordPrefix" placeholder="Prefix letters" :clearable="true" />
                        <q-btn color="primary" label="Load by prefix" @click="loadByFilter" />
                    </div>
                </template>
            </LangSelector>
        </div>

        <ag-grid-vue style="width: 100%; height: 700px; margin: 20px 0" :gridOptions="grid" class="ag-theme-balham" id="grid"></ag-grid-vue>
    </q-page>
</template>
<script setup lang="ts">
import { AgGridVue } from "ag-grid-vue3";
import { CellValueChangedEvent, GridOptions, RowNode, IRowNode } from "ag-grid-community";
import { gridConfig } from "./grid-config";
import { onMounted, reactive, onBeforeMount } from "vue";
import { useQuasar } from "quasar";
import { useRouter } from "vue-router";
import { debounce } from "lodash";
import { store } from "@/store";
import useFetch from "@/tools/fetch";

type TRow = {
    id: string;
    [key: string]: string;
};

const $q = useQuasar();
const headers = new Headers({ "content-type": "application/json", [store.state.tokenHeader]: store.state.authToken });
const url = store.getters.getUrl("/game/wordsearch");
const router = useRouter();

const debounced = debounce(addRow, { maxWait: 1000 });
const grid: GridOptions = gridConfig();
grid.context.actionCallback = actionCallback;
// grid.onGridReady = load;
grid.onCellValueChanged = (event: CellValueChangedEvent) => {
    console.log(event);
    save(event.data, event.node, event.column.getColId());
};

const langPair = { source: "hu", target: "en" };
const loadOptions = [
    { label: "Load 100", value: 100 },
    { label: "Load all", value: 0 },
];
type T_State = {
    grid: GridOptions;
    wordLength: number;
    wordPrefix: string;
};
const state = reactive<T_State>({ grid: grid, wordLength: 0, wordPrefix: "" });

onMounted(() => {
    document.addEventListener("keydown", keyDown);
});

onBeforeMount(() => {
    document.removeEventListener("keydown", keyDown);
});

function keyDown(e) {
    if (e.altKey && e.key == "a") {
        debounced();
    }
}

grid.onGridReady = () => {
    // load();
};

function setQuickFilter(value: string) {
    if (grid.api == null) return;
    grid.api.setQuickFilter(value);
}

function save(data: TRow, node: IRowNode, colId: string) {
    const method = data.id === "99999" ? "POST" : "PUT";
    let empty = true;
    for (let key of Object.keys(data)) {
        if (key == "id") continue;
        if (data[key]) {
            empty = false;
            break;
        }
    }
    if (empty) return;

    const lang = langPair[colId];
    fetch(url, { method, body: JSON.stringify({ [lang]: data[colId], id: data.id }), headers })
        .then((response: Response) => {
            if (response.ok) {
                response.json().then((resp) => {
                    console.log(resp);
                    // node.setData(_data);
                    if (method == "POST") {
                        if (grid.api == null) return;

                        grid.api.applyTransaction({
                            remove: [{ id: "99999" }],
                        });
                        grid.api.applyTransaction({
                            add: [{ id: resp._id, [colId]: resp[lang] }],
                            addIndex: 0,
                        });
                        if (colId == "source") {
                            grid.api.startEditingCell({ rowIndex: 0, colKey: "target" });
                        }
                    } else {
                        node.setData({ id: resp._id, source: resp[langPair.source], target: resp[langPair.target] });
                    }
                });
            } else {
                response.text().then((text) => {
                    $q.notify({ position: "top", type: "negative", message: text });
                });
                data[colId] = "";
                node.setData(data);
            }
            node.setSelected(false);
        })
        .catch((error: Response) => {
            $q.notify({ position: "top", type: "negative", message: error.statusText });
        });
}
function addRow() {
    // const node = grid.api.getRowNode("0");
    // if (node) return;
    console.log(grid);
    try {
        if (grid.api == null) return;
        try {
            grid.api.applyTransaction({
                remove: [{ id: "99999" }],
            });
        } catch (e) {}
        grid.api.applyTransaction({
            add: [{ id: "99999", source: null, target: null }],
            addIndex: 0,
        });
        grid.api.startEditingCell({ rowIndex: 0, colKey: "source" });
    } catch (e) {}
}

function langChanged(value: { source: string; target: string }) {
    langPair.source = value.source;
    langPair.target = value.target;
}

function loadAll() {
    console.log("'loadAll");
}

function load(limit = 100) {
    if (langPair.source == "" || langPair.target == "") {
        $q.notify({ position: "top", type: "negative", message: "Source or target language is missing." });
    }
    fetch(`${url}/?source=${langPair.source}&target=${langPair.target}&limit=${limit}`, {
        method: "GET",
        headers: { [store.state.tokenHeader]: store.state.authToken },
    })
        .then((response: Response) => {
            if (response.ok) {
                response.json().then((data) => {
                    if (grid.api == null) return;
                    const gridData = data.map((item: { _id: string; [s: string]: string }) => {
                        return { id: item._id, source: item[langPair.source], target: item[langPair.target] };
                    });
                    grid.api.setRowData(gridData);
                });
            } else {
                router.replace("/login");
            }
        })
        .catch((error: Response) => {
            $q.notify({ position: "top", type: "negative", message: error.statusText });
            router.replace("/login");
        });
}

async function loadByFilter() {
    let query = `${url}/?source=${langPair.source}`;
    if (state.wordLength) {
        query += `&length=${state.wordLength}`;
    } else if (state.wordPrefix) {
        query += `&prefix=${state.wordPrefix}`;
    } else {
        return;
    }

    const resp = await useFetch(query, { method: "GET" });
    if (resp) {
        const gridData = resp.map((item: { id: string; [s: string]: string }) => {
            return { id: item._id, source: item[langPair.source] };
        });
        grid.api?.setRowData(gridData);
    }
}

function actionCallback(action: string, node: RowNode) {
    // console.log(action, node);
    switch (action) {
        case "delete":
            if (node.data.id === "99999") {
                if (grid.api == null) return;
                // node.setData(null);
                grid.api.applyTransaction({ remove: [{ id: node.data.id }] });
            } else {
                fetch(`${url}/${node.data.id}`, { method: "DELETE", headers })
                    .then((response: Response) => {
                        if (response.ok) {
                            if (grid.api == null) return;
                            // console.log(node.data._id);
                            grid.api.applyTransaction({ remove: [{ id: node.data.id }] });
                        } else {
                            response.text().then((text) => {
                                $q.notify({ position: "top", type: "negative", message: text });
                            });
                        }
                    })
                    .catch((error: Response) => {
                        $q.notify({ position: "top", type: "negative", message: error.statusText });
                    });
            }
            break;
    }
}
</script>
<style></style>
