<template>
    <q-page>
        <StatHeader @loadData="loadData" :multiple="true" :partners-visible="true" :totalTime="totalTime"></StatHeader>
        <AgGridVue style="width: 100%; height: 80vh; margin: 20px 0" class="ag-theme-balham" :gridOptions="grid"></AgGridVue>
    </q-page>
</template>

<script lang="ts" setup>
import { AgGridVue } from "ag-grid-vue3";

import { ref, Ref } from "vue";

import { store } from "@/store";
import StatHeader from "../StatHeaderRooms.vue";
import { gridOptions } from "./grid-config";
import { GridOptions } from "ag-grid-community";

import useFetch from "@/tools/fetch";
import { utils } from "@/tools/utils";

type T_PlayingTimeResponse = {
    partnerName: string;
    gameTime: number;
};

const totalTime = ref("0");

const url = store.getters.getUrl("/stat/partners");

const grid: GridOptions = gridOptions();

grid.onGridReady = () => {};

async function loadData(pids: string | string[], start: string, stop: string, rooms: number[]) {
    grid.api?.setRowData([]);
    console.log(pids, start, stop);
    let tt = 0;
    const data = await useFetch(`${url}/totalplayingtime`, { method: "POST", body: { pids, start, stop, rooms } });
    data.forEach((element) => {
        tt += element.gameTime;
    });
    totalTime.value = utils.formatGameTime(tt);
    grid.api?.applyTransaction({ add: data });
}
</script>
<style></style>
