<template>
    <q-card class="q-px-md">
        <q-card-section class="row items-center">
            <span>Endpoints</span>
        </q-card-section>

        <q-card-section>
            <div class="row q-gutter-x-md">
                <div class="col-3">
                    <q-input v-model="state.lobbyEndpoint" label="Lobby" clearable dense>
                        <template v-slot:append>
                            <q-btn round dense flat icon="add" @click="add(endpoints.lobby, state.lobbyEndpoint)" />
                        </template>
                    </q-input>
                    <q-list bordered separator>
                        <q-item v-for="item in endpoints.lobby">
                            <q-item-section>{{ item }}</q-item-section>
                            <q-item-section top side>
                                <div class="text-grey-8 q-gutter-xs">
                                    <q-btn class="gt-xs" size="12px" flat dense round icon="delete" @click="deleteEndpoint('lobby', item)" />
                                </div>
                            </q-item-section>
                        </q-item>
                    </q-list>
                </div>
                <div class="col-3">
                    <q-input v-model="state.tableEndpoint" label="Table" clearable dense
                        ><template v-slot:append> <q-btn round dense flat icon="add" @click="add(endpoints.table, state.tableEndpoint)" /> </template
                    ></q-input>
                    <q-list bordered separator>
                        <q-item v-for="item in endpoints.table">
                            <q-item-section>{{ item }}</q-item-section>
                            <q-item-section top side>
                                <div class="text-grey-8 q-gutter-xs">
                                    <q-btn class="gt-xs" size="12px" flat dense round icon="delete" @click="deleteEndpoint('table', item)" />
                                </div>
                            </q-item-section>
                        </q-item>
                    </q-list>
                </div>
                <div class="col-3">
                    <q-input v-model="state.gameEndpoint" label="Game" clearable dense>
                        <template v-slot:append> <q-btn round dense flat icon="add" @click="add(endpoints.game, state.gameEndpoint)" /> </template
                    ></q-input>

                    <q-list bordered separator>
                        <q-item v-for="item in endpoints.game">
                            <q-item-section>{{ item }}</q-item-section>
                            <q-item-section top side>
                                <div class="text-grey-8 q-gutter-xs">
                                    <q-btn class="gt-xs" size="12px" flat dense round icon="delete" @click="deleteEndpoint('game', item)" />
                                </div>
                            </q-item-section>
                        </q-item>
                    </q-list>
                </div>
            </div>
        </q-card-section>
    </q-card>
</template>
<script setup lang="ts">
import { onMounted, reactive, computed, ref, toRefs } from "vue";
import { store } from "@/store";
import { useQuasar } from "quasar";
import useFetch from "@/tools/fetch";
import * as model from "./model";
import { IEndpoints } from "./model";
import { IEndpoint } from "../Partner/model";

interface IProps {
    endpoints: IEndpoints;
}

const props = withDefaults(defineProps<IProps>(), {
    endpoints: () => {
        return { lobby: [], table: [], game: [] };
    },
});

const { endpoints } = toRefs(props);

interface IState {
    lobbyEndpoint: string;
    tableEndpoint: string;
    gameEndpoint: string;
}

const state: IState = reactive({
    lobbyEndpoint: "",
    tableEndpoint: "",
    gameEndpoint: "",
});

const url = store.getters.getUrl();

async function add(endpointList: string[], newItem: string) {
    if (newItem) {
        if (!endpointList.includes(newItem)) {
            endpointList.push(newItem);
            save();
        }
    }
}

function deleteEndpoint(endpointName: string, endpoint: string) {
    endpoints.value[endpointName] = endpoints.value[endpointName].filter((item) => item != endpoint);
    save();
}

async function save() {
    await useFetch(`${url}/settings`, {
        method: "PUT",
        body: { key: "endpoints", value: { lobby: endpoints.value.lobby, table: endpoints.value.table, game: endpoints.value.game } },
    });
}
</script>
<style></style>
