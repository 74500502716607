<template>
    <q-page class="row items-center">
        <q-card class="login-card" style="min-width: 400px">
            <q-card-section class="bg-primary text-white">
                <div class="text-h6">Login</div>
            </q-card-section>
            <q-card-section>
                <q-form>
                    <q-input
                        v-model="state.username"
                        type="text"
                        label="Username"
                        ref="usernameRef"
                        :rules="[(val) => !!val || '* Required']"
                        lazy-rules
                        @focus="resetValidation()"
                    />
                    <q-input
                        ref="passwordRef"
                        v-model="state.password"
                        :type="isPwd ? 'password' : 'text'"
                        label="Password"
                        :rules="[(val) => !!val || '* Required', (val) => val.length > 3 || 'Minimum 4 character']"
                        lazy-rules
                        @focus="resetValidation()"
                    >
                        <template v-slot:append>
                            <q-icon :name="isPwd ? 'visibility_off' : 'visibility'" class="cursor-pointer" @click="isPwd = !isPwd" />
                        </template>
                    </q-input>
                </q-form>
            </q-card-section>
            <q-card-section class="row justify-end">
                <q-btn color="primary" text-color="white" label="Login" @click="onLogin" :disabled="btnDisabled" />
            </q-card-section>
        </q-card>
    </q-page>
</template>
<script lang="ts">
import { defineComponent, onMounted, reactive, computed, ref } from "vue";
import { QField, useQuasar } from "quasar";
import { useRouter } from "vue-router";
import { store } from "@/store";

export default defineComponent({
    props: {},
    setup(props, { attrs, slots, emit }) {
        const $q = useQuasar();
        const router = useRouter();

        const headers = new Headers({ "content-type": "application/json" });
        const url = store.getters.getUrl();

        const state = reactive({ username: "", password: "" });
        const usernameRef = ref(null);
        const passwordRef = ref(null);
        const isPwd = ref(true);

        function onLogin() {
            fetch(`${url}/login`, { method: "POST", body: JSON.stringify({ username: state.username, password: state.password }), headers })
                .then((response: Response) => {
                    if (response.ok) {
                        // response.headers.forEach((item) => {
                        //     console.log(item);
                        //     console.log(response.headers.get(item));
                        // });
                        // router.replace("/wordsearch");
                        if (response.headers.has(store.state.tokenHeader)) {
                            const token = response.headers.get(store.state.tokenHeader);
                            store.state.authToken = token || "";
                        }

                        router.replace("/partners");
                    }
                })
                .catch((error: Response) => {
                    $q.notify({ position: "top", type: "negative", message: error.statusText });
                });
        }
        const btnDisabled = computed(() => {
            return state.password.length < 4 || state.username.length < 4;
        });
        onMounted(() => {
            onLogin();
        });

        function resetValidation() {
            if (usernameRef.value) {
                (usernameRef.value as QField).resetValidation();
            }
            if (passwordRef.value) {
                (passwordRef.value as QField).resetValidation();
            }
        }

        return {
            state,
            usernameRef,
            passwordRef,
            isPwd,
            onLogin,
            btnDisabled,
            resetValidation,
        };
    },
});
</script>
<style></style>
