<template>
    <q-page>
        <StatHeader @loadData="loadData" :multiple="false" :partnersVisible="true" :totalTime="totalTime"></StatHeader>
        <div class="col">
            <PlayingTime :playingTimeData="playingTimeData"></PlayingTime>
            <GameStat :gameStatData="gameStatData"></GameStat>
        </div>
    </q-page>
</template>

<script lang="ts" setup>
import { ref, Ref } from "vue";

import { store } from "@/store";
import StatHeader from "../StatHeader.vue";

import PlayingTime, { T_PlayingTimeResponse } from "./PlayingTime.vue";
import GameStat, { T_GameStatResponse } from "@/components/GameStat.vue";

import useFetch from "@/tools/fetch";
import { utils } from "@/tools/utils";
import { AgCharts } from "ag-charts-vue3";
import { AgCartesianChartOptions } from "ag-charts-community";

const url = store.getters.getUrl("/stat/partners");

const playingTimeData: Ref<T_PlayingTimeResponse[]> = ref([]);
const gameStatData: Ref<T_GameStatResponse[]> = ref([]);
const totalTime = ref("0");

async function loadData(pid: string | string[], start: string, stop: string) {
    // console.log(pid, start, stop);

    playingTimeData.value = await useFetch(`${url}/playingtime`, { method: "POST", body: { pid, start, stop } });
    gameStatData.value = await useFetch(`${url}/gamestat`, { method: "POST", body: { pid, start, stop } });

    let total = 0;
    for (const record of playingTimeData.value) {
        total += record.gameTime;
    }
    totalTime.value = utils.formatGameTime(total);
}
</script>
<style></style>
