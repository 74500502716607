<template>
    <q-card style="height: 70vh; width: 900px; max-width: 80vw" class="q-px-md">
        <q-card-section class="row items-center">
            <span>Terminal</span>
        </q-card-section>

        <q-card-section style="display: flex; flex-direction: column; width: 100%">
            <div style="display: flex; justify-content: space-between">
                <q-input style="width: 70%" v-model="activeCommand" label="Command" clearable dense />
                <div>
                    <q-btn flat label="Save" color="primary" @click="onSave" />
                    <q-btn flat label="Send" color="primary" @click="onSend" />
                </div>
            </div>
            <div style="width: 80%; align-self: flex-start">
                <q-select
                    v-model="selectedFromList"
                    :options="commandList"
                    @update:model-value="onSelect"
                    @clear="onRemove"
                    label="Command history"
                    dense
                    clearable
                />
            </div>
            <div id="history" style="height: 400px; background-color: antiquewhite; margin-top: 25px; overflow-y: scroll"></div>
        </q-card-section>
        <q-card-section>
            <q-btn style="" flat label="Clear" color="primary" @click="onClear" />
            <q-btn style="float: right" flat label="Close" color="primary" @click="onClose" v-close-popup />
        </q-card-section>
    </q-card>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import useFetch from "@/tools/fetch";
import { store } from "@/store";

const url = store.getters.getUrl("/table");

const props = defineProps<{
    tid: string;
}>();

const activeCommand = ref<string>("");
const selectedFromList = ref<string>("");
const commandList = ref<string[]>([]);

function onSave() {
    if (activeCommand.value) {
        let cmd = activeCommand.value.trim();
        commandList.value.unshift(cmd);
        saveLocalstore();
    }
}

async function onSend() {
    if (activeCommand.value) {
        addLine([activeCommand.value], "client");
        const message = { cmd: "runCommand", value: activeCommand.value };
        const resp = (await useFetch(`${url}/command`, { method: "POST", body: { cmd: "shell", message, tids: [props.tid] } })) as string[];
        if (resp) {
            addLine(resp, "server");
        }
    }
}

function onSelect(value: string) {
    activeCommand.value = value;
}

function onRemove(value: string) {
    commandList.value = commandList.value.filter((item: string) => {
        return item != value;
    });
    saveLocalstore();
}

function onClear() {
    const el = document.getElementById("history");
    el?.replaceChildren();

    // while (el?.lastElementChild) {
    //     el.removeChild(el.lastElementChild);
    // }
}

function onClose() {}

function saveLocalstore() {
    localStorage.setItem("shellhistory", JSON.stringify(commandList.value));
}

function addLine(message: string[], source: string) {
    const color = source == "client" ? "black" : "blue";

    for (const line of message) {
        // const line = text.replace(/ /g, &nbsp);
        const div = document.createElement("div");
        div.style.color = color;
        div.textContent = line;

        document.getElementById("history")?.appendChild(div);
    }
}

onMounted(() => {
    const history = localStorage.getItem("shellhistory");
    if (history) {
        commandList.value = JSON.parse(history);
    }
});
</script>
