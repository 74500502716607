<template>
    <q-card class="items-center justify-start" style="height: auto; min-width: 400px; max-width: 80vw">
        <q-card-section class="text-h6 bg-primary text-white">
            <div class="row justify-start">
                <q-btn flat label="Set endpoint" />
            </div>
        </q-card-section>
        <q-card-section class="column q-gutter-md">
            <q-input class="col" v-model="hid" label="HID" clearable />
            <q-select filled v-model="model" :options="MODELS" label="Table model" />
            <!-- <q-select use-chips v-model="tables" :options="allTables" map-options emit-value label="Tables" multiple class="col" /> -->

            <div class="row justify-end q-pt-md text-primary">
                <q-icon @click="onSave()" size="24px" name="save" class="q-ml-md" />
            </div>
        </q-card-section>
    </q-card>
</template>
<script setup lang="ts">
import { store } from "@/store";
import useFetch from "@/tools/fetch";
import { Notify } from "quasar";
import { ref } from "vue";
import { IPartner } from "@/pages/Partner/model";

interface IModel {
    label: string;
    value: string;
}

const MODELS: IModel[] = [
    { value: "schd32", label: "Sunchip 32 Android 9" },
    { value: "schd32-RK3588", label: "Sunchip 32 Android 12" },
    { value: "mgd32", label: "Multigold" },
    { value: "bvs32", label: "BVS 32" },
];

const props = defineProps<{
    pid: string;
}>();

const hid = ref("");
const model = ref<IModel>();
const closePopup = ref(false);

const emit = defineEmits(["dialogEvent"]);

async function onSave() {
    if (!hid.value || !model.value) {
        Notify.create({ type: "negative", message: "Missing data!" });
    }
    const body = { hid: hid.value, model: model.value?.value };

    const resp = await useFetch(`${store.getters.getUrl()}/table/new/${props.pid}`, { method: "POST", body });

    if (resp) {
        Notify.create({ type: "positive", message: "Data has been saved." });
        closePopup.value = true;
        emit("dialogEvent", { action: "loadTables" });
        // emit("eventEditor", { action: "endpoint", endpoint });
    }
}
</script>
<style></style>
