export default class Checkbox {
    constructor() {}

    init(params) {
        this.eGui = document.createElement("div");
        this.eGui.innerHTML = `<span><input class="ag-checkbox" type="checkbox"></span>`;
        this.cb = this.eGui.querySelector(".ag-checkbox");
        if (params.disabled !== undefined) {
            this.cb.disabled = params.disabled;
        }
        this.cb.checked = params.value;
        this.eventListener = () => {
            params.node.setDataValue(params.colDef.field, this.cb.checked);
            // params.api.updateRowData({ update: [params.data] });
        };
        this.cb.addEventListener("change", this.eventListener);
    }

    getGui() {
        return this.eGui;
    }

    refresh(params) {
        this.cb.checked = params.value;
        return true;
    }

    destroy() {
        this.cb.removeEventListener("change", this.eventListener);
    }
}
