import { Notify } from "quasar";
import router from "@/router";
import { store } from "@/store";

type T_Params = {
    method: string;
    body?: any;
    headers?: Headers;
};

type T_Headers = {
    "content-type": string;
};

export default async function useFetch(url, params: T_Params, json = true): Promise<any> {
    if (params.body && json) {
        params.body = JSON.stringify(params.body);
    }

    params.headers = params.headers ? params.headers : new Headers({ "Content-Type": "application/json" });
    // params.headers.forEach((v, k) => {
    //     console.log(v, k);
    // });

    params.headers.set(store.state.tokenHeader, store.state.authToken);
    // params.headers.forEach((v, k) => {
    //     console.log(v, k);
    // });
    try {
        store.commit("resetLastAction");
        const res = await fetch(url, params);

        if (res.status == 401) {
            router.replace("/login");
            return;
        }
        console.log(res);
        if (res.ok) {
            if (res.headers.has(store.state.tokenHeader)) {
                const token = res.headers.get(store.state.tokenHeader);
                store.state.authToken = token || "";
            }

            try {
                const data = await res.json();
                return data || {};
            } catch (e) {
                return {};
            }
        } else {
            const message = await res.text();
            Notify.create({ position: "top", type: "negative", message: message });
        }
    } catch (err: any) {
        Notify.create({ position: "top", type: "negative", message: err.message });
    }
}
