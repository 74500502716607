<template>
    <q-page class="q-pa-md">
        <div class="row items-end justify-between q-gutter-lg">
            <div class="row items-end justify-between q-gutter-lg">
                <q-input v-model="state.quickFilter" @update:model-value="setQuickFilter" placeholder="Filter" />
                <q-btn color="primary" label="Load" @click="loadTables()" />
                <q-btn color="primary" label="New" @click="newTable()" />
            </div>

            <!-- <q-btn color="primary" label="Add partner" @click="addRow()" /> -->
        </div>
        <AgGridVue style="width: 100%; height: 80vh; margin: 20px 0" class="ag-theme-balham" :gridOptions="state.grid"></AgGridVue>
        <q-dialog
            stye="max-height: 80vh; "
            v-model="state.dialogVisible"
            transition-show="slide-up"
            transition-hide="slide-down"
            v-on:close="state.dialogVisible = false"
        >
            <component
                :is="dialog"
                v-on:dialogEvent="dialogEvent"
                :partner="selectedRow()"
                v-on:eventEditor="eventEditor($event)"
                :pid="state.pid"
                :table="selectedRow()"
                :grid="grid"
            ></component>
        </q-dialog>
    </q-page>
</template>
<script setup lang="ts">
import { onMounted, reactive, computed, ref, onBeforeMount, markRaw, defineAsyncComponent, shallowRef, VueElement, nextTick } from "vue";
import { AgGridVue } from "ag-grid-vue3";
import { CellValueChangedEvent, GridOptions, GridSerializer, RowNode, RowClickedEvent, CellClickedEvent } from "ag-grid-community";
import { gridOptions } from "./grid-config";
import { store, T_Game } from "@/store";
import { useQuasar } from "quasar";
import useFetch from "@/tools/fetch";
import DeleteDialog from "@/components/DeleteDialog.vue";
import NewTable from "./NewTable.vue";
import PartnerSelector from "./PartnerSelector.vue";
import TableColor from "./TableColor.vue";

import { ITable } from "./model";
import BackgroundImage from "./BackgroundImage.vue";

type T_State = {
    grid: GridOptions;
    quickFilter: string;
    dialogVisible: boolean;
    pid: string;
    table: ITable | null;
};

type T_EditorEvent = {
    action: string;
    timeBudget?: number;
    data?: any;
};

interface IDialogEvent {
    action: string;
    data?: any;
}

const props = defineProps<{
    pid: string;
}>();

const BASE_ID = "1111";
// const DeleteDialog = defineAsyncComponent(() => import("./editors/DeleteDialog.vue"));
const dialog = shallowRef<any>(null);

const grid: GridOptions = gridOptions();
grid.context.actionCallback = actionClick;
const $q = useQuasar();

const url = store.getters.getUrl();

const state: T_State = reactive({ grid: grid, quickFilter: "", dialogVisible: false, pid: props.pid, table: null }) as T_State;
let deleteAction: string = "";

function setQuickFilter() {
    grid.api?.setQuickFilter(state.quickFilter);
}

grid.onGridReady = async () => {
    // grid.rowSelection = "single";
    grid.onRowClicked = (event: RowClickedEvent) => {};
    grid.onCellValueChanged = (event: CellValueChangedEvent) => {
        if (event.data.id == BASE_ID) return;

        let field = event.colDef.field as string;
        let value: any;
        if (field.startsWith("version")) {
            value = Object.assign(event.data["version"], { version: Number(event.data.version.version) });
            field = "version";
        } else {
        }
        switch (field) {
            default:
                value = event.data[field];
        }
        saveSingleItem(event.data.tid, field, value);
        // save(event.data);
    };
    // grid.onCellMouseOver = cellMouseOver;
    grid.onCellClicked = (event: CellClickedEvent) => {
        switch (event.colDef.field) {
            case "customization.lobby_background":
                state.table = event.data;
                dialog.value = BackgroundImage;
                state.dialogVisible = true;
                break;
            case "tableColor":
                dialog.value = TableColor;
                state.dialogVisible = true;
                break;
        }
    };
    await loadTables();
};

async function loadTables() {
    const pid = props.pid;
    grid.api?.setRowData([]);
    const tables: ITable[] = await useFetch(`${url}/table/${pid}`, { method: "GET" });
    if (tables) {
        grid.api?.applyTransaction({ add: tables });
    } else {
    }
}

function newTable() {
    dialog.value = NewTable;
    state.dialogVisible = true;
}

function addRow() {
    grid.api?.applyTransaction({ remove: [{ hid: "1111" }] });
    // grid.columnApi!.setColumnsVisible(["assetsId", "username", "partnerCode", "private]"], true);
    grid.columnApi!.applyColumnState({
        defaultState: { sort: null },
    });

    grid.api?.applyTransaction({
        add: [{}],
        addIndex: 0,
    });
}

function selectedRow(): ITable | null {
    if (grid.api?.getSelectedNodes().length) {
        console.log("selected: ", grid.api?.getSelectedNodes()[0].data);
        return grid.api?.getSelectedNodes()[0].data;
    }
    return null;
}

async function actionClick(event: string, data: any) {
    console.log(event, data);

    switch (event) {
        case "edit":
            state.dialogVisible = true;
            // dialog.value = ActionSelector;

            break;
        case "save":
            await save(data);
            break;

        case "delete":
            window.setTimeout(() => {
                const selected = selectedRow();
                if (selected) {
                    dialog.value = DeleteDialog;
                    state.dialogVisible = true;
                    deleteAction = "deleteTable";
                }
            }, 50);

            break;
        case "flight_takeoff":
            dialog.value = PartnerSelector;
            state.dialogVisible = true;
            break;
    }
}

async function deleteTable() {
    const selected = selectedRow();
    deleteAction = "";
    if (selected) {
        if (selected.hid == BASE_ID) {
            grid.api?.applyTransaction({ remove: [{ id: BASE_ID }] });
        } else {
            const resp = await useFetch(`${url}/table/${selected.tid}`, { method: "DELETE" });
            if (resp) {
                grid.api?.applyTransaction({ remove: [{ hid: selected.hid }] });
            }
        }
        grid.columnApi!.applyColumnState({
            state: [{ colId: "tableName", sort: "asc" }],
            defaultState: { sort: null },
        });
    }
}

async function relocateTable(targetPid: string) {
    const selected = selectedRow();
    const resp = await useFetch(`${url}/table/relocate`, { method: "POST", body: { hid: selected?.hid, targetPid: targetPid } });
    if (resp) {
        loadTables();
    }
}

async function save(table: ITable) {
    if (!validateSave(table)) return;

    const _table: ITable = await useFetch(`${url}/table/${props.pid}`, { method: "PUT", body: table });
    if (_table) {
        grid.api?.applyTransaction({ update: [_table] });
    } else {
    }
}

async function saveSingleItem(tid: string, key: string, value: any) {
    // console.log(pid, key, value);
    await useFetch(`${url}/table`, { method: "PUT", body: { tid, key, value } });
}

function validateSave(table: ITable): boolean {
    if (table.hid == "1111") {
        $q.notify({ position: "top", type: "negative", message: "Missing valid hid" });
        return false;
    }

    return true;
}

async function dialogEvent(event: IDialogEvent) {
    console.log("Dialog: ", event);
    switch (event.action) {
        case "delete":
            if (dialog.value === DeleteDialog) {
                switch (deleteAction) {
                    case "deleteTable":
                        await deleteTable();
                        break;
                }
                state.dialogVisible = false;
                dialog.value = null;
            }

            break;
        case "loadTables":
            await loadTables();
            state.dialogVisible = false;
            dialog.value = null;
            break;
        case "relocateTable":
            state.dialogVisible = false;
            dialog.value = null;
            await relocateTable(event.data as string);
            break;
        case "tableColor":
            setNewColor(event.data.value, event.data.label);
            break;
    }
}

function setNewColor(color: string, tableName: string) {
    const node = grid.api?.getSelectedNodes()[0];
    if (node) {
        node.setDataValue("tableColor", color);
        node.setDataValue("tableName", tableName);
    }

    // grid.api?.applyTransaction({ update: [_table] });
}

function eventEditor(event: T_EditorEvent) {
    switch (event.action) {
        case "timeBudget":
            const row = selectedRow();
            if (row) {
                grid.api?.applyTransaction({ update: [selectedRow] });
            }
            break;
        case "updatePartner":
            // this.updatePartner(this.selectedRow.pid);
            break;
    }
}
</script>
<style></style>
