<template>
    <q-page>
        <StatHeader @loadData="loadData" :multiple="true" :partnersVisible="true"></StatHeader>
        <AgCharts :options="chartOptions" style="height: 600px"></AgCharts>
    </q-page>
</template>

<script setup lang="ts">
import { ref, Ref, defineProps, watch, toRef, pushScopeId } from "vue";
import { AgCharts } from "ag-charts-vue3";
import { utils } from "@/tools/utils";
import { AgCartesianChartOptions, AgChartTheme, AgCartesianSeriesOptions } from "ag-charts-community";
import useFetch from "@/tools/fetch";
import { store } from "@/store";
import StatHeader from "../StatHeader.vue";

type T_DailyPlayingTimeResponse = {
    pid: string;
    year: string;
    month: string;
    day: string;
    gameTime: number;
    partnerName: string;
};

interface T_PartnerData {
    name: string;
    gameTime: number;
    pid: string;
}

interface T_PartnerItem {
    [key: string]: T_PartnerData;
}

interface T_GroupData {
    ts: number;
    date: string;
    pid: string;
}

type T_GroupItem = T_GroupData;

interface T_Group {
    ts: number;
    date: string;
    pid: string;
    name: string;
    gameTime: number;
}

interface T_IndexSigNumberValue {
    [key: string]: number;
}
interface T_ChartData {
    ts: number;
    date: string;
}

type T_ChartItem = T_ChartData | T_IndexSigNumberValue;

const url = store.getters.getUrl("/stat/partners");

const chartOptions: Ref<AgCartesianChartOptions> = ref({});

const myTheme: AgChartTheme = {
    baseTheme: "ag-default",
    palette: {
        fills: [
            "#87CEEB",
            "#49FF75",
            "#FF0000",
            "#FFDF48",
            "#800080",
            "#8A2BE2",
            "#008080",
            "#FA7921",
            "#c6ff00",
            "#A0522D",
            "#800000",
            "#808000",
            "#ADFF2F",
            "#EE82EE",
            "#FFDAB9",
            "#FFD700",
            "#FF4500",
            "#FFB6C1",
            "#0000CD",
        ],
        strokes: ["black"],
    },
    overrides: {
        // cartesian: {
        //     title: {
        //         fontSize: 24,
        //     },
        //     series: {
        //         column: {
        //             label: {
        //                 enabled: true,
        //                 color: "black",
        //             },
        //         },
        //     },
        // },
    },
};

async function loadData(pids: string | string[], start: string, stop: string) {
    console.log(pids, start, stop);

    const data: T_DailyPlayingTimeResponse[] = await useFetch(`${url}/dailyplayingtime`, { method: "POST", body: { pids, start, stop } });
    setChart(data);
}

function setChart(statResponse: T_DailyPlayingTimeResponse[]) {
    const { sorted, series } = getChartData(statResponse);
    chartOptions.value = {
        theme: myTheme,

        // width: 1600,
        // height: 600,
        data: sorted,
        // container: document.getElementById("chart"),
        // autoSize: true,

        series,
        axes: [
            {
                type: "category",
                position: "bottom",
                label: {
                    rotation: 30,
                },
            },
            {
                type: "number",
                position: "left",
                label: {
                    formatter: function (params) {
                        return utils.secToHour(params.value) + "h";
                    },
                },
            },
        ],
        legend: {
            enabled: true,
            spacing: 40,
            position: "bottom",
        },
    };
}

function getChartData(statResponse: T_DailyPlayingTimeResponse[]) {
    const group = groupByDate(statResponse);
    const data: {}[] = [];
    const partners = {};
    // const fills: Set<string> = new Set();

    const series: AgCartesianSeriesOptions[] = [];

    for (let item of group) {
        if (!(item.pid in partners)) {
            partners[item.pid] = true;
            series.push({ yKey: item.pid, type: "bar", xKey: "date", yName: item.name, tooltip: tooltip() });
        }

        data.push({ date: item.date, [item.pid]: item.gameTime, ts: item.ts });
    }
    const sorted = data.sort((a: any, b: any) => {
        if (a.ts === b.ts) return 0;
        return a.ts < b.ts ? -1 : 1;
    });
    return { sorted, series };
}

function tooltip() {
    return {
        renderer: function (params) {
            return {
                title: params.yName,
                content: utils.secToHour(params.yValue) + "h",
            };
        },
    };
}

function groupByDate(statResponse: T_DailyPlayingTimeResponse[]): T_Group[] {
    const group: T_Group[] = [];
    statResponse.map((item) => {
        const d = `${item.year}-${item.month}-${item.day}`;
        const ts = new Date(d).getTime();
        group.push({ date: d, ts: ts, name: item.partnerName, gameTime: item.gameTime, pid: item.pid });
    });
    return group;
}
</script>
<style></style>
