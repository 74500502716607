import { T_Partner } from "@/model/partner";
import { InjectionKey } from "vue";
import { createStore, useStore as baseUseStore, Store } from "vuex";

export type T_Game = {
    value: string;
    label: string;
};

type TColor = {
    [key: string]: string;
};

export interface State {
    version: number;
    baseUrl: string;
    currentRoute: string;
    activeMenu: string;
    authenticated: boolean;
    games: T_Game[];
    langs: string[];
    lastAction: number;
    enabledLang: { id: string; label: string }[];
    authToken: string;
    tokenHeader: string;
    partners: T_Partner[];
    tableColors: TColor;
}

export const key: InjectionKey<Store<State>> = Symbol();

export const store = createStore<State>({
    modules: {},
    state: {
        version: 100,
        baseUrl: "/api/admin/v2",
        currentRoute: "/",
        activeMenu: "",
        authenticated: false,
        games: [],
        langs: ["hu", "en", "de"],
        lastAction: Date.now(),
        enabledLang: [
            { id: "hu", label: "Magyar" },
            { id: "en", label: "English" },
            { id: "de", label: "Deutsch" },
            { id: "ro", label: "Roman" },
        ],
        authToken: "",
        tokenHeader: "Auth-Token",
        partners: [],
        tableColors: {
            Blue: "#87CEEB",
            Green: "#49FF75",
            Red: "#FF0000",
            Yellow: "#FFDF48",
            Purple: "#800080",
            BlueViolet: "#8A2BE2",
            Teal: "#008080",
            Orange: "#FA7921",
            Lime: "#c6ff00",
            Brown: "#A0522D",
            Maroon: "#800000",
            SkyBlue: "#0000CD",
            Olive: "#808000",
            GreenYellow: "#ADFF2F",
            Violet: "#EE82EE",
            Peach: "#FFDAB9",
            Gold: "#FFD700",
            OrangeRed: "#FF4500",
            Pink: "#FFB6C1",
        },
    },
    getters: {
        currentRoute: (state) => {
            return state.currentRoute;
        },
        getUrl: (state) => (path) => {
            // let url = location.hostname == "admin.touchgametable.com" ? "https://api.tgt.cool/api/admin/v2" : "/api/admin/v2";
            let url = "/api/admin/v2";
            if (path) {
                url += path;
            }
            return url;
        },
        games: (state) => {
            return state.games.map((game: T_Game) => {
                return game.value;
            });
        },
    },
    mutations: {
        games: (state, games: T_Game[]) => {
            state.games = games.sort((a: T_Game, b: T_Game) => {
                if (a.label == b.label) return 0;
                return a.label < b.label ? -1 : 1;
            });
        },
        activeMenu: (state, activeMane) => {
            state.activeMenu = activeMane;
        },

        authenticated: (state, status) => {
            if (status) {
                state.authenticated = true;
                localStorage.setItem("authenticated", "yes");
            } else {
                state.authenticated = false;
                localStorage.setItem("authenticated", "");
            }
        },
        resetLastAction: (state) => {
            state.lastAction = Date.now();
        },
        partners: (state, partners) => {
            state.partners = partners;
        },
    },
});

// define your own `useStore` composition function
export function useStore() {
    return baseUseStore(key);
}
