<template>
    <div class="row q-gutter-md items-end">
        <div class="col-2" v-if="props.partnersVisible">
            <q-select v-model="state.selectedPartner" :options="partnerOptions" dense options-dense clearable label="Partner" :multiple="props.multiple">
            </q-select>
        </div>
        <div class="col-3" style="min-width: 270px">
            <q-input v-model="state.dateInput" label="Select date range" class="full-width">
                <template v-slot:append>
                    <div class="text-primary">
                        <q-icon name="event" class="cursor-pointer">
                            <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                                <q-date v-model="state.date" range mask="YYYY-MM-DD" @update:model-value="onDateChange">
                                    <div class="row items-center justify-end">
                                        <q-btn v-close-popup label="Close" color="primary" flat />
                                    </div>
                                </q-date>
                            </q-popup-proxy>
                        </q-icon>
                        <q-icon name="cloud_download" class="cursor-pointer q-pl-xl" @click="onLoad"></q-icon>
                    </div>
                </template>
            </q-input>
        </div>
        <div class="col-1">
            <q-input v-model="state.totalTime" label="Total time" readonly />
        </div>
    </div>
</template>
<script lang="ts" setup>
import { reactive, computed, onBeforeMount, onMounted, defineEmits, toRef, watch } from "vue";
import { useQuasar } from "quasar";
import { store } from "@/store";
import { utils } from "@/tools/utils";
import useFetch from "@/tools/fetch";

type T_Partner = {
    pid: string;
    partnerName: string;
    city: string;
};

type T_Select = {
    value: string;
    label: string;
    city: string;
};

type T_State = {
    selectedPartner: T_Select | T_Select[];
    partners: T_Partner[];
    dateInput: string;
    date: { from: string; to: string };
    totalTime: string;
};

type T_Props = {
    multiple: boolean;
    partnersVisible?: boolean;
    totalTime: string;
};
const props = withDefaults(defineProps<T_Props>(), {
    multiple: false,
    partnersVisible: true,
    totalTime: "0",
});

const emit = defineEmits<{
    (e: "loadData", pid: string | string[], start: string, stop: string): void;
}>();

// const emit = defineEmits<{
//     (e: "loadData", start: string, stop: string): void;
// }>();

const url = store.getters.getUrl("/stat/partners");
const $q = useQuasar();
let totalTime: any = toRef(props, "totalTime");

const state: T_State = reactive({
    selectedPartner: { label: "", value: "", city: "" },
    partners: [],
    date: { from: "", to: "" },
    dateInput: "",
    totalTime: props.totalTime,
});

watch(totalTime, (newValue: string, oldValue: string) => {
    if (oldValue != newValue) {
        state.totalTime = newValue;
    }
});

onBeforeMount(async () => {
    const data: T_Partner[] = await useFetch(`${url}`, { method: "GET" });
    if (data) {
        state.partners = data.sort((a: T_Partner, b: T_Partner) => {
            const aa = utils.accentedTextFormatter(a.partnerName);
            const bb = utils.accentedTextFormatter(b.partnerName);
            if (aa == bb) return 0;
            return aa < bb ? -1 : 1;
        });

        if (!props.multiple && data.length) {
            state.selectedPartner = { value: data[0].pid, label: data[0].partnerName, city: data[0].city };
        } else {
            state.selectedPartner = [];
        }
    }
});

onMounted(() => {
    const start = new Date(Date.now());
    // const start = new Date("2022-01-08");
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);

    state.date.from = utils.formatDate(start);
    const stop = new Date(Date.now());
    state.date.to = utils.formatDate(stop);
    state.dateInput = getDateInput();
});

function getDateInput() {
    return state.date != null ? `${state.date.from} - ${state.date.to}` : "";
}

const partnerOptions = computed(() => {
    const options = state.partners.map((item: T_Partner) => {
        return { label: item.partnerName, value: item.pid, city: item.city };
    });
    // console.log(options);
    return options;
});

function onLoad() {
    // console.log(state.date.from, state.date.to);
    const start = state.date.from + "T00:00:00Z";
    const stop = state.date.to + "T23:59:59Z";
    let pids: string[] = [];
    if (props.partnersVisible) {
        if (!state.selectedPartner && !props.multiple) {
            $q.notify({ position: "top", type: "negative", message: "Select a partner" });
        }
    }
    // console.log(state.selectedPartner);
    if (props.multiple) {
        if (!state.selectedPartner || (state.selectedPartner as Array<T_Select>).length == 0) {
            pids = state.partners.map((item) => item.pid);
        } else {
            pids = (state.selectedPartner as Array<T_Select>).map((item) => item.value);
        }

        emit("loadData", pids, start, stop);
    } else {
        if (state.selectedPartner && !Array.isArray(state.selectedPartner)) {
            emit("loadData", state.selectedPartner.value, start, stop);
        }
    }
}

function onDateChange() {
    state.dateInput = `${state.date.from} - ${state.date.to}`;
    // console.log(console.log(state.date.from, state.date.to));
}
</script>
<style></style>
