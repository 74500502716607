<template>
    <q-card class="items-center justify-start" style="height: auto; width: 300px">
        <q-card-section class="text-h6 bg-primary text-white">
            <div>Select an action</div>
        </q-card-section>

        <q-card-actions class="column items-center">
            <q-list>
                <q-item clickable v-ripple v-for="item in ACTIONS">
                    <q-item-section @click="onClick(item.value)">{{ item.label }}</q-item-section>
                </q-item>
            </q-list>
        </q-card-actions>
    </q-card>
</template>
<script setup lang="ts">
import { reactive } from "vue";
import router from "@/router";
import { IPartner } from "../model";

const ACTIONS = [
    { label: "Business data", value: "editBusinessData" },
    { label: "Payment users ", value: "editManager" },
    { label: "Tables", value: "table" },
    { label: "Endpoints", value: "endpoint" },
];
const state = reactive({});
const emit = defineEmits(["dialogEvent"]);

const props = defineProps<{
    partner: IPartner;
}>();

function onClick(action: string) {
    emit("dialogEvent", { action });
}
</script>
<style></style>
