<template>
    <div class="col-1 q-mr-md">
        <q-input v-model="state.quickFilter" @update:model-value="setQuickFilter" placeholder="Filter" />
    </div>
    <!-- </el-col> -->
    <!-- <el-col :span="10"> -->
    <div class="row items-end q-gutter-md q-gutter-x-md">
        <q-select
            v-model="state.sourceLang"
            :options="store.state.enabledLang"
            label="Source lang"
            style="min-width: 200px"
            @update:model-value="langChanged()"
        >
            <template v-slot:option="scope">
                <q-item v-bind="scope.itemProps">
                    <q-item-section>
                        <q-item-label>{{ scope.opt.label }}</q-item-label>
                    </q-item-section>
                </q-item>
            </template>
        </q-select>
        <q-select
            v-model="state.targetLang"
            :options="store.state.enabledLang"
            @update:model-value="langChanged()"
            label="Target lang"
            style="min-width: 200px"
        >
            <template v-slot:option="scope">
                <q-item v-bind="scope.itemProps">
                    <q-item-section>
                        <q-item-label>{{ scope.opt.label }}</q-item-label>
                    </q-item-section>
                </q-item>
            </template>
        </q-select>
        <div class="q-gutter-x-md row">
            <slot name="buttons"></slot>
        </div>
    </div>

    <div class="q-gutter-x-md q-ml-auto">
        <q-btn color="primary" label="Add" @click="addRow()" />
        <!-- <q-btn color="primary" label="Save all" @click="saveAll()" /> -->
    </div>
</template>

<script setup lang="ts">
import { store } from "@/store";
import { onMounted, reactive, computed, ref } from "vue";

import { useQuasar } from "quasar";

const $q = useQuasar();

type T_Lang = {
    id: string;
    label: string;
};

type T_State = {
    quickFilter: string;
    sourceLang: T_Lang;
    targetLang: T_Lang;
};

const state: T_State = reactive<T_State>({
    quickFilter: "",
    sourceLang: { id: "hu", label: "Magyar" },
    targetLang: { id: "en", label: "English" },
});

const emit = defineEmits(["langChanged", "addRow", "quickFilter", "loadLang"]);

function setQuickFilter() {
    emit("quickFilter", state.quickFilter);
}

function addRow() {
    emit("addRow");
}

function langChanged() {
    emit("langChanged", { source: state.sourceLang.id, target: state.targetLang.id });
}

onMounted(() => {
    langChanged();
});
</script>
<style></style>
