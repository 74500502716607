<template>
    <q-page class="q-pa-md">
        <ImageUpload :pid="'tgt'" :url="baseUrl" v-on:fileUploaded="onFileUploaded" v-on:close="emit('close')"></ImageUpload>

        <div class="col row q-gutter-md">
            <div class="column items-center" v-for="(item, index) in state.imageRecord" :key="index">
                <div class="col q-gutter-md">
                    <q-img style="width: 220px" :src="item.url" @click="onFullImage(item)"></q-img>
                </div>
                <div class="full-width col row jusify-between col-2 items-center">
                    <div class="col">{{ metaLang(item) }}</div>
                    <q-icon class="col" name="delete" :color="getTrashColor(item)" style="font-size: 2em" @click="onDelete(item)"></q-icon>
                    <q-icon class="col" name="edit" style="font-size: 2em" @click="onClickImage(item)"></q-icon>
                </div>
            </div>
        </div>
        <q-dialog v-model="state.dialogVisible" transition-show="slide-up" transition-hide="slide-down" v-on:close="state.dialogVisible = false">
            <ImageMetaEditor v-on:dialogEvent="dialogEvent" :imageRecord="state.selectedImage"></ImageMetaEditor>
        </q-dialog>
        <q-dialog v-model="confirmDelete" persistent>
            <q-card>
                <q-card-section class="row items-center">
                    <q-avatar icon="signal_wifi_off" color="primary" text-color="white" />
                    <span class="q-ml-sm">Image is used by partner.</span>
                </q-card-section>
                <q-card-section class="row items-center">
                    <div>{{ getPartnerUsage(activeRecord?.partnerUsage) }}</div>
                </q-card-section>

                <q-card-actions align="right">
                    <q-btn flat label="Cancel" color="primary" v-close-popup />
                    <q-btn flat label="Delete" color="primary" v-close-popup @click="onDelete(activeRecord as I_ImageRecord)" />
                </q-card-actions>
            </q-card>
        </q-dialog>
        <q-dialog v-model="state.fullImageVisible" transition-show="slide-up" transition-hide="slide-down" v-on:close="state.fullImageVisible = false">
            <q-img style="max-width: 80vw; height: auto" :src="state.selectedImage?.url"></q-img>
        </q-dialog>
    </q-page>
</template>
<script setup lang="ts">
import { onMounted, reactive, markRaw, ref } from "vue";
import { useQuasar } from "quasar";
import useFetch from "@/tools/fetch";
import { store } from "@/store";
import { I_ImageRecord, I_ImageMeta } from "./model";
import ImageMetaEditor from "./ImageMetaEditor.vue";
import ImageUpload from "@/components/ImageUpload.vue";

type T_State = {
    imageRecord: I_ImageRecord[];
    loadUrl: string;
    dialogVisible: boolean;
    fullImageVisible: boolean;
    selectedImage: I_ImageRecord | null;
};

const baseUrl = `${store.getters.getUrl()}/privatepuzzle`;

const props = defineProps<{
    pid: string;
}>();

const emit = defineEmits(["close"]);
const activeRecord = ref<I_ImageRecord>();

const confirmDelete = ref(false);
const $q = useQuasar();
const state: T_State = reactive({ imageRecord: [], loadUrl: "", dialogVisible: false, fullImageVisible: false, selectedImage: null }) as T_State;
markRaw(state);

function metaLang(rec: I_ImageRecord) {
    let langs = "";
    for (let key of Object.keys(rec.meta)) {
        if (rec.meta[key].title) {
            langs += key + ",";
        }
    }
    return langs;
}

function onFullImage(rec: I_ImageRecord) {
    state.selectedImage = rec;
    state.fullImageVisible = true;
}

function onClickImage(rec: I_ImageRecord) {
    state.selectedImage = rec;
    state.dialogVisible = true;
}

function getTrashColor(rec: I_ImageRecord) {
    return rec.partnerUsage && rec.partnerUsage.length > 0 ? "grey" : "green";
}

async function dialogEvent(event: { cmd: string; imageRecord: I_ImageRecord }) {
    console.log("Dialog: ", event);
    switch (event.cmd) {
        case "save":
            await update(event.imageRecord);
            break;
    }
}

function getPartnerUsage(partners: string[] | undefined) {
    if (partners) {
        return partners.join(",");
    }
}

async function update(imageRecord: I_ImageRecord) {
    const resp = await useFetch(`${baseUrl}`, { method: "PUT", body: imageRecord });
    if (resp) {
    }
}

async function onDelete(rec: I_ImageRecord) {
    if (rec.partnerUsage.length > 0) {
        activeRecord.value = rec;
        confirmDelete.value = true;
    } else {
        deleteImage(rec);
    }
}

async function deleteImage(rec: I_ImageRecord) {
    const resp = await useFetch(`${baseUrl}`, { method: "DELETE", body: { file: rec.file } });
    if (resp) {
        state.imageRecord = state.imageRecord.filter((item: I_ImageRecord) => item.id != rec.id);
    }
}

function onFileUploaded(imageRecord: I_ImageRecord) {
    for (let lang of store.state.enabledLang) {
        imageRecord.meta[lang.id] = { title: "", label: "", message: "", what: "" };
    }
    state.imageRecord.unshift(imageRecord);
}

function eventEditor() {}

async function loadImages(id = "0", limit = 500) {
    const resp: I_ImageRecord[] = await useFetch(`${baseUrl}?limit=${limit}&id=${id}`, { method: "GET" });
    if (resp) {
        if (id == "0") {
            state.imageRecord.length = 0;
        }
        for (let item of resp) {
            for (let lang of store.state.enabledLang) {
                if (!(lang.id in item.meta)) {
                    item.meta[lang.id] = { title: "", label: "", message: "", what: "" };
                }
            }

            state.imageRecord.push(item);
        }
    }
}

onMounted(async () => {
    await loadImages();
});
</script>
<style></style>
