<template>
    <AgCharts :options="chartOptions" style="height: 300px"></AgCharts>
</template>

<script lang="ts" setup>
import { ref, Ref, defineProps, watch, toRef } from "vue";
import { utils } from "@/tools/utils";
import { AgCartesianChartOptions, AgCartesianSeriesOptions } from "ag-charts-community";
import { AgCharts } from "ag-charts-vue3";
import { store } from "../../../store/index";

export type T_PlayingTimeResponse = {
    pid: string;
    partnerName: string;
    gameTime: number;
};

type TChartData = {
    yKey: number;
    partnerName: string;
};

type T_Props = {
    playingTimeData: T_PlayingTimeResponse[];
};
const props = defineProps<T_Props>();

const chartOptions: Ref<AgCartesianChartOptions> = ref({});

const playingTimeData = toRef(props, "playingTimeData");
const partnerNames = {};

watch(playingTimeData, (newValue: T_PlayingTimeResponse[]) => {
    setChart(playingTimeData.value);
});

function setChart(statResponse: T_PlayingTimeResponse[]) {
    const { data, series } = getChartData(statResponse);
    chartOptions.value = {
        // height: 600,
        data,
        // container: document.getElementById("chart"),
        //autoSize: true,
        series,
        axes: [
            {
                type: "category",
                position: "bottom",
                label: {
                    rotation: 30,
                },
            },
            {
                type: "number",
                position: "left",
                label: {
                    formatter: function (params) {
                        return utils.secToHour(params.value) + "h";
                    },
                },
            },
        ],
        legend: {
            enabled: false,
            spacing: 40,
            position: "bottom",
        },
    };
}

function getChartData(statResponse: T_PlayingTimeResponse[]) {
    const data = getData(statResponse);
    const series = getSeries(data);
    return { data, series };
}

function getData(statResponse: T_PlayingTimeResponse[]): TChartData[] {
    const data: TChartData[] = [];

    for (let i = 0; i < statResponse.length; i++) {
        const item = statResponse[i];
        partnerNames[item.pid] = item.partnerName;
        const record = {
            partnerName: item.partnerName,
            yKey: item.gameTime,
        };
        data.push(record);
    }

    data.sort((a, b) => {
        return a.yKey < b.yKey ? 1 : -1;
    });

    return data;
}

function getSeries(data: any[]): AgCartesianSeriesOptions[] {
    const series: AgCartesianSeriesOptions[] = [];

    // for (let item of data) {
    series.push({ type: "bar", xKey: "partnerName", yKey: "yKey", tooltip: tooltip() });
    // }

    return series;
}

function tooltip() {
    return {
        renderer: function ({ datum }) {
            console.log("Datum: ", datum);
            return {
                title: datum.partnerName,
                content: utils.secToHour(datum.yKey) + "h",
            };
        },
    };
}
</script>
<style></style>
