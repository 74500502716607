<template>
    <q-card class="items-center justify-start" style="height: auto; min-width: 400px; max-width: 80vw">
        <q-card-section class="text-h6 bg-primary text-white">
            <div>Partner data</div>
        </q-card-section>
        <q-card-section>
            <q-input dense v-model="partnerData.company" label="Company" />
            <q-input dense v-model="partnerData.country" label="Country" />
            <q-input dense v-model="partnerData.city" label="City" />
            <q-input dense v-model="partnerData.address" label="Address" />
            <q-input dense v-model="partnerData.postCode" label="Post code" />
        </q-card-section>
        <q-card-section class="text-h6 bg-secondary text-white">
            <div>Top contact</div>
        </q-card-section>

        <q-card-section>
            <q-input dense v-model="partnerData.topContact.name" label="Name" />
            <q-input dense v-model="partnerData.topContact.email" label="Email" type="email" />
            <q-input dense v-model="partnerData.topContact.phone" label="Phone" />
        </q-card-section>

        <q-card-section class="text-h6 bg-secondary text-white">
            <div>Operative contact</div>
        </q-card-section>

        <q-card-section>
            <q-input dense v-model="partnerData.operativeContact.name" label="Name" />
            <q-input dense v-model="partnerData.operativeContact.email" label="Email" type="email" />
            <q-input dense v-model="partnerData.operativeContact.phone" label="Phone" />
        </q-card-section>
        <q-card-actions align="right">
            <q-btn dense label="Cancel" flat color="primary" v-close-popup />
            <q-btn dense label="Save" flat color="primary" @click="onSave" />
        </q-card-actions>
    </q-card>
</template>
<script setup lang="ts">
import { onMounted, reactive, computed, ref } from "vue";
import { Notify } from "quasar";
import useFetch from "@/tools/fetch";
import { store } from "@/store";
import { IPartner, IPartnerData } from "../model";

const props = defineProps<{
    partner: IPartner;
}>();

const partnerData = ref<IPartnerData>(props.partner["partnerData"]);

const state = reactive({});

async function onSave() {
    const resp = await useFetch(`${store.getters.getUrl()}/partner`, {
        method: "PUT",
        body: { id: props.partner.id, key: "partnerData", value: partnerData.value },
    });
    if (resp) {
        Notify.create({ type: "positive", message: "Data has been saved." });
    }
}
</script>
<style></style>
