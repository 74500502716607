<template>
    <q-card class="items-center justify-start q-pa-md" style="min-width: 800px; max-width: 80vw; height: auto">
        <q-card-section class="col q-gutter-md">
            <div class="row q-gutter-md">
                <q-toggle v-model="imageRecord.public" color="green" label="Public" left-label />
                <q-toggle v-model="imageRecord.hide" color="green" label="Hide" left-label />
                <q-select
                    v-model="activeLang"
                    :options="store.state.enabledLang"
                    label="Source lang"
                    style="min-width: 200px; margin-left: 100px"
                    @update:model-value="langChanged()"
                >
                    <template v-slot:option="scope">
                        <q-item v-bind="scope.itemProps">
                            <q-item-section>
                                <q-item-label>{{ scope.opt.label }}</q-item-label>
                            </q-item-section>
                        </q-item>
                    </template>
                </q-select>
            </div>
            <div class="col q-gutter-md" style="min-height: 100px; margin-top: 50px">
                <div v-for="item in META_KEY" :key="item.id">
                    <q-input class="col" v-model="imageRecord.meta[activeLang.id][item.id]" filled type="text" :label="item.label" dense stack-label />
                </div>
                <q-input class="col" v-model="tag" filled type="text" label="Tag" dense stack-label />
                <q-input class="col" v-model="imageRecord.origFile" filled type="text" label="Original file" dense stack-label />
            </div>
        </q-card-section>

        <q-card-actions align="right">
            <q-btn flat label="Close" color="primary" v-close-popup />
            <q-btn flat label="Save" color="primary" @click="onClick('save')" />
        </q-card-actions>
    </q-card>
</template>
<script setup lang="ts">
import { store } from "@/store";
import { reactive, ref, onMounted } from "vue";
import { I_ImageRecord, I_ImageMeta } from "./model";

interface I_LangMeta {
    [key: string]: I_ImageMeta;
}

const META_KEY = [
    { id: "title", label: "Title" },
    { id: "what", label: "What is it?" },
    { id: "label", label: "Label" },
    { id: "message", label: "Message" },
];

const props = defineProps<{
    imageRecord: I_ImageRecord | null;
}>();

const emit = defineEmits(["dialogEvent"]);
const imageRecord = ref<I_ImageRecord>(props.imageRecord as I_ImageRecord);
const activeLang = ref<{ id: string; label: string }>({ id: "hu", label: "Magyar" });
const tag = ref<string>("");

const state = reactive({});

function getImageUrl() {}

function onClick(event) {
    if (event == "save") {
        const tags: string[] = tag.value.split(",").map((t: string) => {
            return t.trim();
        });

        imageRecord.value.tag = tags;
        emit("dialogEvent", { cmd: "save", imageRecord: imageRecord.value });
    }
}

function langChanged() {
    console.log(activeLang.value);
}

onMounted(() => {
    if ("tag" in imageRecord.value && Array.isArray(imageRecord.value.tag)) {
        tag.value = imageRecord.value.tag.join(",");
    }
});
</script>
<style></style>
