<template>
    <q-layout view="hHh lpr lFf">
        <q-header elevated>
            <q-toolbar>
                <q-btn flat dense round icon="menu" aria-label="Menu" @click="state.leftDrawer = !state.leftDrawer" />

                <q-btn stretch flat label="Users" to="/users" />
                <q-btn-dropdown stretch flat label="Partners">
                    <q-list>
                        <q-item clickable v-close-popup to="/partners">
                            <q-item-section>
                                <q-item-label>Partner list</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item clickable v-close-popup to="/hotelshungary">
                            <q-item-section>
                                <q-item-label>Hungary hotels </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item clickable v-close-popup to="/hotelsaustria">
                            <q-item-section>
                                <q-item-label>Austria hotels </q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item clickable v-close-popup to="/creditusage">
                            <q-item-section>
                                <q-item-label>Credit usage</q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                </q-btn-dropdown>

                <q-btn-dropdown stretch flat label="Tables">
                    <q-list>
                        <q-item clickable v-close-popup to="/tables">
                            <q-item-section>
                                <q-item-label>Active tables</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item clickable v-close-popup to="/parking-tables">
                            <q-item-section>
                                <q-item-label>Parking tables</q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                </q-btn-dropdown>

                <q-btn-dropdown stretch flat label="Games">
                    <q-list>
                        <q-item clickable v-close-popup to="/puzzle">
                            <q-item-section>
                                <q-item-label>Puzzle</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item clickable v-close-popup to="/funhistory">
                            <q-item-section>
                                <q-item-label>Fun History</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item clickable v-close-popup to="/wordsearch">
                            <q-item-section>
                                <q-item-label>Word Search</q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                </q-btn-dropdown>
                <q-btn-dropdown stretch flat label="Statistics">
                    <q-list>
                        <q-item clickable v-close-popup to="/partnerstat">
                            <q-item-section>
                                <q-item-label>Partner stat</q-item-label>
                            </q-item-section>
                        </q-item>

                        <q-item clickable v-close-popup to="/dailyplayingtime">
                            <q-item-section>
                                <q-item-label>Compare partners daily stat</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item clickable v-close-popup to="/hourlyplayingtime">
                            <q-item-section>
                                <q-item-label>Compare partners hourly playing time</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item clickable v-close-popup to="/partnerstotalstatgrid">
                            <q-item-section>
                                <q-item-label>Partners total playing time excel</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item clickable v-close-popup to="/partnerstotalstat">
                            <q-item-section>
                                <q-item-label>Compare partners total stat</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item clickable v-close-popup to="/totalgamestat">
                            <q-item-section>
                                <q-item-label>Total game stat</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item clickable v-close-popup to="/gamesplayingtimebypartners">
                            <q-item-section>
                                <q-item-label>Games Playingtime by Partner</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item clickable v-close-popup to="/partnerslinechart">
                            <q-item-section>
                                <q-item-label>Partners Line Chart</q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                </q-btn-dropdown>
                <q-btn stretch flat label="Monitor" to="/monitor" />
                <q-btn-dropdown stretch flat label="Marketing">
                    <q-list>
                        <q-item clickable v-close-popup to="/puzzlemarketing">
                            <q-item-section>
                                <q-item-label>PuzzleMarketing</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item clickable v-close-popup to="/info">
                            <q-item-section>
                                <q-item-label>Info Queries</q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                </q-btn-dropdown>
                <q-btn-dropdown stretch flat label="Other">
                    <q-list>
                        <q-item clickable v-close-popup to="/settings">
                            <q-item-section>
                                <q-item-label>TGT Settings</q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                </q-btn-dropdown>
                <q-space />
                <q-btn stretch flat label="Log out" @click="logOut" />
                <q-btn flat dense round icon="menu" aria-label="Menu" @click="state.rightDrawer = !state.rightDrawer" />
            </q-toolbar>
        </q-header>

        <q-drawer v-model="state.leftDrawer" show-if-above bordered content-class="bg-grey-1">
            <q-list>
                <q-item-label header class="text-grey-8">Essential Links</q-item-label>
            </q-list>
        </q-drawer>
        <q-drawer side="right" v-model="state.rightDrawer" show-if-above bordered content-class="bg-grey-1">
            <q-list>
                <q-item-label header class="text-grey-8">Essential Links</q-item-label>
            </q-list>
        </q-drawer>

        <q-page-container class="q-pa-sm">
            <!-- <transition :name="transitionName"> -->
            <router-view></router-view>
            <!-- </transition> -->
        </q-page-container>
    </q-layout>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, computed } from "vue";
import { useRouter } from "vue-router";
import { store } from "@/store";

export default defineComponent({
    name: "MainLayout",
    props: {},
    components: {},
    setup(props, { attrs, slots, emit }) {
        const headers = new Headers({ "content-type": "application/json", [store.state.tokenHeader]: store.state.authToken });
        const url = store.getters.getUrl("/logout");
        const router = useRouter();

        const state = reactive({
            leftDrawer: true,
            rightDrawer: true,
        });

        onMounted(() => {
            state.leftDrawer = !state.leftDrawer;
            state.rightDrawer = !state.rightDrawer;
        });
        function logOut() {
            fetch(url, { method: "GET", headers })
                .then((response: Response) => {
                    if (response.ok) {
                        router.replace("/login");
                    }
                })
                .catch((error: Response) => {
                    router.replace("/login");
                });
        }

        return {
            logOut,
            state,
        };
    },
});
</script>
