import { store } from "@/store";
import useFetch from "@/tools/fetch";
import { GridOptions, ModelUpdatedEvent } from "ag-grid-community";
import { Notify } from "quasar";
import { I_GridRow } from "./model";

export namespace action {
    const url = store.getters.getUrl();

    export async function save(grid: GridOptions, row: { data: I_GridRow }) {
        if (!row.data.partnerName) {
            Notify.create({
                type: "negative",
                message: "Name or Assets or Partner Code id is missing.",
            });
            return;
        }

        const filter = ["table"];
        let method = "POST";
        if (row.data.id == "1111") {
            filter.push("id");
        }

        const filteredData = {};
        for (let [k, v] of Object.entries(row.data)) {
            if (!filter.includes(k)) {
                switch (k) {
                    case "rooms":
                        filteredData[k] = Number(v);
                        break;
                    default:
                        filteredData[k] = v;
                }
            }
        }

        const resp = (await useFetch(`${url}/partner`, { method, body: filteredData })) as I_GridRow;
        if (resp) {
            if (row.data.id == "1111") {
                grid.api?.applyTransaction({ remove: [{ id: "1111" }] });
                grid.api?.applyTransaction({ add: [resp], addIndex: 0 });
            } else {
                const node = grid.api?.getSelectedNodes()[0];
                node?.setData(resp);
                grid.api?.applyTransaction({ update: [node] });
            }
            Notify.create({ type: "positive", message: "Data has been saved." });
        }
    }

    export async function saveSingleItem(id: string, key: string, value: any) {
        // console.log(pid, key, value);
        await useFetch(`${url}/partner`, { method: "PUT", body: { id, key, value } });
    }

    export async function deleteRecord(grid: GridOptions, id: string) {
        const res = await useFetch(`${url}/partner/${id}`, { method: "DELETE" });
        if (res) {
            grid.api?.applyTransaction({ remove: [grid.api?.getSelectedNodes()[0]] });
        }

        grid.columnApi!.applyColumnState({
            state: [{ colId: "partnerName", sort: "asc" }],
            defaultState: { sort: null },
        });
    }
}
