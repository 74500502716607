const dammTable = [
    [0, 3, 1, 7, 5, 9, 8, 6, 4, 2],
    [7, 0, 9, 2, 1, 5, 4, 8, 6, 3],
    [4, 2, 0, 6, 8, 7, 1, 3, 5, 9],
    [1, 7, 5, 0, 9, 8, 3, 4, 2, 6],
    [6, 1, 2, 3, 0, 4, 5, 9, 7, 8],
    [3, 6, 7, 4, 2, 0, 9, 5, 8, 1],
    [5, 8, 6, 9, 7, 2, 0, 1, 3, 4],
    [8, 9, 4, 5, 3, 6, 2, 0, 1, 7],
    [9, 4, 3, 8, 6, 1, 7, 2, 0, 5],
    [2, 5, 8, 1, 4, 3, 6, 7, 9, 0],
];

export function checkDamm(number) {
    return calcDamm(number) == 0;
}

function calcDamm(basenumber) {
    // Damm Algorighm
    let s, damm;
    damm = 0;
    s = String(basenumber);
    for (let i = 0; i < s.length; ++i) {
        damm = dammTable[damm][s.charAt(i)];
    }
    return damm;
}

export function formatDate(d) {
    let date;
    if (d.getMonth) {
        date = d;
    } else {
        let tz = -1 * new Date().getTimezoneOffset();
        date = new Date(d * 1000);
    }
    let year = date.getFullYear();
    let month = date.getMonth() < 9 ? `0${date.getMonth() + 1}` : `${date.getMonth() + 1}`;
    let day = date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`;

    return `${year}-${month}-${day}`;
}

export function formatDateTime(d) {
    let date;
    if (d.getMonth) {
        date = d;
    } else {
        let tz = -1 * new Date().getTimezoneOffset();
        date = new Date(d * 1000);
    }
    let year = date.getFullYear();
    let month = date.getMonth() < 9 ? `0${date.getMonth() + 1}` : `${date.getMonth() + 1}`;
    let day = date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`;
    let hours = date.getHours() < 10 ? `0${date.getHours()}` : `${date.getHours()}`;
    let minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : `${date.getMinutes()}`;
    let seconds = date.getSeconds() < 10 ? `0${date.getSeconds()}` : `${date.getSeconds()}`;
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export function accentedTextFormatter(s) {
    let r = s.toLowerCase();
    r = r.replace(new RegExp("\\s", "g"), "");
    r = r.replace(new RegExp("[àáâãäå]", "g"), "a");
    r = r.replace(new RegExp("æ", "g"), "ae");
    r = r.replace(new RegExp("ç", "g"), "c");
    r = r.replace(new RegExp("[èéêë]", "g"), "e");
    r = r.replace(new RegExp("[ìíîï]", "g"), "i");
    r = r.replace(new RegExp("ñ", "g"), "n");
    r = r.replace(new RegExp("[òóôõöő]", "g"), "o");
    r = r.replace(new RegExp("œ", "g"), "oe");
    r = r.replace(new RegExp("[ùúûüű]", "g"), "u");
    r = r.replace(new RegExp("[ýÿ]", "g"), "y");
    r = r.replace(new RegExp("\\W", "g"), "");
    return r;
}

export function secToHour(sec) {
    if (typeof sec == "undefined") {
        sec = 0;
    }
    let hours = Math.floor(sec / 3600);
    let minutes = String(Math.floor((sec - hours * 3600) / 60));
    let h = String(hours);
    return `${h.padStart(2, "0")}h:${minutes.padStart(2, "0")}min`;
}
