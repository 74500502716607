import Actions from "../../components/action.vue";
import HeaderCheckbox from "@/components/header-checkbox.js";
import GameEditor from "@/components/GameEditor.vue";
import LangEditor from "@/components/LangEditor.vue";
import GameRenderer from "@/components/GameRenderer.vue";
import { secToHour } from "@/components/utils";
import Checkbox from "@/components/checkbox.js";
import { GridOptions, ColDef, ValueSetterParams, ValueGetterParams } from "ag-grid-community";

export function gridOptions(): GridOptions {
    return {
        pagination: true,
        paginationPageSize: 500,
        context: {
            actionCallback: null,
            actionIcons: [
                {
                    icon: "edit",
                    tooltip: "Edit",
                },
                {
                    icon: "save",
                    tooltip: "Save",
                },
                {
                    icon: "delete",
                    tooltip: "Delete",
                },
            ],
        },
        rowSelection: "single",
        defaultColDef: {
            // allow every column to be aggregated
            enableValue: true,
            // allow every column to be grouped
            enableRowGroup: true,
            // allow every column to be pivoted
            enablePivot: true,
            resizable: true,
            sortable: true,
            filter: true,
        },
        sideBar: {
            toolPanels: [
                {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel",
                },
                {
                    id: "filters",
                    labelDefault: "Filters",
                    labelKey: "filters",
                    iconKey: "filter",
                    toolPanel: "agFiltersToolPanel",
                },
            ],
        },

        onGridReady: () => {},
        components: {
            checkbox: Checkbox,
            gameRenderer: GameRenderer,
        },
        getRowId: function (params) {
            return params.data.id;
        },
        rowData: [],
        columnDefs: columDefs(),
        enableCellChangeFlash: true,
        // refreshCells: true
    };
}

function columDefs(): ColDef[] {
    return [
        {
            headerName: "ID",
            field: "id",
            width: 200,
            editable: true,
            hide: true,
        },
        {
            headerName: "Name",
            field: "name",
            width: 180,
            filter: "text",
            sort: "asc",
            editable: true,
        },
        {
            headerName: "Room number",
            field: "roomNumber",
            width: 100,
            editable: true,
        },
        {
            headerName: "City",
            field: "city",
            width: 120,
            editable: true,
        },

        {
            headerName: "Star",
            field: "star",
            width: 80,
            editable: true,
        },
        {
            headerName: "Director",
            field: "director",
            width: 130,
            editable: true,
        },
        {
            headerName: "Email",
            field: "email",
            width: 150,
            editable: true,
        },
        {
            headerName: "Tel",
            field: "tel",
            width: 130,
            editable: true,
        },
        {
            headerName: "Url",
            field: "url",
            width: 150,
            editable: true,
        },
        {
            headerName: "Address",
            field: "rawAddress",
            width: 180,
            editable: true,
        },
        {
            headerName: "GPS",
            field: "loc.coordinates",
            width: 180,
            editable: false,
            hide: true,
            valueGetter: (params: ValueGetterParams) => {
                return params.data.loc.coordinates ? `${params.data.loc.coordinates[0]}, ${params.data.loc.coordinates[1]}` : "";
            },
        },
    ];
}
