import Actions from "../../components/action.vue";
import HeaderCheckbox from "@/components/header-checkbox.js";
import GameEditor from "@/components/GameEditor.vue";
import LangEditor from "@/components/LangEditor.vue";
import GameRenderer from "@/components/GameRenderer.vue";
import { secToHour } from "@/components/utils";
import Checkbox from "@/components/checkbox.js";
import { GridOptions, ColDef, ValueSetterParams, ValueGetterParams, ValueFormatterParams } from "ag-grid-community";

export function gridOptions(): GridOptions {
    return {
        pagination: true,
        paginationPageSize: 500,
        context: {
            actionCallback: null,
            actionIcons: [
                {
                    icon: "edit",
                    tooltip: "Edit",
                },
                {
                    icon: "save",
                    tooltip: "Save",
                },
                {
                    icon: "delete",
                    tooltip: "Delete",
                },
                {
                    icon: "flight_takeoff",
                    tooltip: "Relocate table",
                },
            ],
        },
        rowSelection: "single",
        defaultColDef: {
            // allow every column to be aggregated
            enableValue: true,
            // allow every column to be grouped
            enableRowGroup: true,
            // allow every column to be pivoted
            enablePivot: true,
            resizable: true,
            sortable: true,
            filter: true,
        },
        sideBar: {
            toolPanels: [
                {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel",
                },
                {
                    id: "filters",
                    labelDefault: "Filters",
                    labelKey: "filters",
                    iconKey: "filter",
                    toolPanel: "agFiltersToolPanel",
                },
            ],
        },

        onGridReady: () => {},
        components: {
            checkbox: Checkbox,
            gameRenderer: GameRenderer,
        },
        getRowId: function (params) {
            return params.data.hid;
        },
        rowData: [],
        columnDefs: columDefs(),
        enableCellChangeFlash: true,
        // refreshCells: true
    };
}

function columDefs(): ColDef[] {
    return [
        {
            colId: "checkbox",
            maxWidth: 50,
            checkboxSelection: true,
            suppressMenu: true,
            headerCheckboxSelection: true,
        },
        {
            headerName: "HID",
            field: "hid",
            width: 150,
            editable: true,
            hide: true,
        },
        {
            headerName: "TID",
            field: "tid",
            width: 150,
            editable: true,
            hide: false,
        },

        {
            headerName: "Free",
            field: "free",
            width: 80,
            editable: true,
            cellRenderer: "checkbox",
        },
        {
            headerName: "Game time",
            field: "payment.gameTime",
            width: 90,
            editable: true,
        },
        {
            headerName: "Buy credit",
            field: "payment.whereToBuy",
            width: 100,
            editable: true,
            valueGetter: (params: ValueGetterParams) => {
                try {
                    return params.data.payment.whereToBuy.hu;
                } catch (e) {
                    return "Empty";
                }
            },
            valueSetter: (params: ValueSetterParams) => {
                if (params.oldValue != params.newValue) {
                    try {
                        params.data.payment.whereToBuy.hu = params.newValue;
                        return true;
                    } catch (e) {}
                }
                return false;
            },
        },
        {
            headerName: "Table name",
            field: "tableName",
            width: 100,
            editable: true,
        },
        {
            headerName: "Table color",
            field: "tableColor",
            width: 100,
            editable: true,
            cellStyle: (params) => {
                return { color: "white", backgroundColor: params.value };
            },
        },
        {
            headerName: "Background image",
            field: "customization.lobby_background",
            width: 150,
            editable: false,
        },

        {
            headerName: "Model",
            field: "tableModel",
            width: 100,
            editable: true,
        },
        {
            headerName: "Version number",
            field: "version.version",
            width: 100,
            editable: true,
        },
        {
            headerName: "Update enabled",
            field: "version.updateEnabled",
            width: 100,
            editable: true,
            cellRenderer: "checkbox",
        },
        {
            headerName: "Comment",
            field: "comment",
            width: 200,
            editable: true,
        },
        {
            headerName: "Actions",
            field: "action",
            // width: 160,
            cellRenderer: Actions,
            suppressMenu: true,
            pinned: "right",
            // suppressSizeToFit: true,
        },
    ];
}
