<template>
    <q-card class="items-center justify-start" style="height: auto; min-width: 400px; max-width: 80vw">
        <q-card-section class="text-h6 bg-primary text-white">
            <div class="row justify-start">
                <q-btn flat label="Select a new color for table" />
            </div>
        </q-card-section>
        <q-card-section class="column q-gutter-md">
            <q-select dense v-model="selectedColor" :options="COLORS" label="Colors" hint="Select a color">
                <template v-slot:option="scope">
                    <q-item v-bind="scope.itemProps">
                        <q-item-section>
                            <q-item-label :style="{ color: 'white', backgroundColor: scope.opt.value }">{{ scope.opt.label }}</q-item-label>
                        </q-item-section>
                    </q-item>
                </template>
            </q-select>
        </q-card-section>

        <q-card-section class="row">
            <q-btn class="col" color="primary" label="Select" @click="select()" />
        </q-card-section>
    </q-card>
</template>
<script setup lang="ts">
import { ref, computed } from "vue";

const COLORS = [
    { label: "Blue", value: "#0000CD" },
    { label: "Green", value: "#49FF75" },
    { label: "Red", value: "#FF0000" },
    { label: "Yellow", value: "#FFDF48" },
    { label: "Purple", value: "#800080" },
    { label: "BlueViolet", value: "#8A2BE2" },
    { label: "Teal", value: "#008080" },
    { label: "Orange", value: "#FA7921" },
    { label: "Lime", value: "#c6ff00" },
    { label: "Brown", value: "#A0522D" },
    { label: "Maroon", value: "#800000" },
    { label: "SkyBlue", value: "#87CEEB" },
    { label: "Olive", value: "#808000" },
    { label: "GreenYellow", value: "#ADFF2F" },
    { label: "Violet", value: "#EE82EE" },
    { label: "Peach", value: "#FFDAB9" },
    { label: "Gold", value: "#FFD700" },
    { label: "OrangeRed", value: "#FF4500" },
    { label: "Pink", value: "#FFB6C1" },
];

interface IPartner {
    label: string;
    value: string;
}

const selectedColor = ref(null);

const emit = defineEmits(["dialogEvent"]);

function select() {
    emit("dialogEvent", { action: "tableColor", data: selectedColor.value });
}
</script>
<style></style>
