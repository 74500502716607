import Checkbox from "@/components/checkbox.js";
import Actions from "@/components/action.vue";
import { GridOptions, ColDef } from "ag-grid-community";

export function gridConfig(): GridOptions {
    return {
        pagination: true,
        paginationPageSize: 500,
        context: {
            // componentParent: ctx,
            actionCallback: null,
            actionIcons: [
                // {
                //     icon: "edit",
                //     tooltip: "Edit",
                // },
                // {
                //     icon: "save",
                //     tooltip: "Save",
                // },
                {
                    icon: "delete",
                    tooltip: "Delete",
                },
            ],
        },
        rowSelection: "single",
        defaultColDef: {
            // allow every column to be aggregated
            // enableValue: true,
            // allow every column to be grouped
            // enableRowGroup: true,
            // allow every column to be pivoted
            // enablePivot: true,
            resizable: true,
            sortable: true,
            filter: "agTextColumnFilter",
            editable: true,
            width: 150,
        },
        sideBar: {
            toolPanels: [
                {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel",
                },
                {
                    id: "filters",
                    labelDefault: "Filters",
                    labelKey: "filters",
                    iconKey: "filter",
                    toolPanel: "agFiltersToolPanel",
                },
            ],
        },

        components: {
            checkbox: Checkbox,
        },
        getRowId: function (params) {
            return params.data.id;
        },
        rowData: [],
        columnDefs: columDefs(),
        enableCellChangeFlash: true,
        // refreshCells: true
    };
}

function columDefs(): ColDef[] {
    return [
        {
            headerName: "ID",
            field: "id",
            width: 200,
            hide: false,
            editable: false,
            sortable: true,
        },
        {
            headerName: "Source",
            field: "source",
        },
        {
            headerName: "Target",
            field: "target",
        },

        {
            headerName: "Actions",
            field: "action",
            // width: 160,
            cellRendererFramework: Actions,
            suppressMenu: true,
            pinned: "right",
            // suppressSizeToFit: true,
        },
    ];
}
