<template>
    <q-card class="items-center justify-start" style="height: auto; width: 60vw; max-width: 80vw; height: 80vh">
        <q-card-section>
            <ImageUpload :pid="pid" :url="url + '/lobbybgimage'" v-on:fileUploaded="onFileUploaded" v-on:close="emit('close')"></ImageUpload>
        </q-card-section>
        <q-card-section>
            <div class="column items-center col-5">
                <q-img alt="Active image" :src="getActiveImageUrl" :ratio="16 / 9" style="width: 270px; border: 1px solid"></q-img>
                <p class="q-pt-md col">Active background</p>
            </div>
        </q-card-section>
        <q-card-section>
            <div>
                <q-btn-toggle
                    v-model="state.partnerSelector"
                    :options="[
                        { label: 'Partner', value: 'partner' },
                        { label: 'TGT', value: 'tgt' },
                    ]"
                    style="border: 1px solid #027be3"
                    no-caps
                    rounded
                    unelevated
                    toggle-color="primary"
                    color="white"
                    text-color="primary"
                    @update:modelValue="partnerChanged"
                />
            </div>
        </q-card-section>
        <q-card-section>
            <div class="row q-gutter-md">
                <div class="column items-center" v-for="(item, index) in state.images" :key="index">
                    <div class="col q-gutter-md">
                        <q-img style="width: 220px" :src="item.url"></q-img>
                    </div>
                    <div class="row jusify-around col-2 items-center">
                        <q-icon name="delete" color="red" style="font-size: 2em" @click="onDelete(item)"></q-icon>
                        <q-toggle color="green" v-model="state.toggle[index]" @update:modelValue="onSetNewBackground(index)" />
                    </div>
                </div>
            </div>
        </q-card-section>
    </q-card>
</template>
<script setup lang="ts">
import { reactive, computed, ref, markRaw, onBeforeMount } from "vue";
import ImageUpload from "@/components/ImageUpload.vue";
import { useQuasar } from "quasar";
import useFetch from "@/tools/fetch";
import { store } from "@/store";
import { I_ImageRecord } from "@/store/models/image-model";
import { IImageRecord, ITable } from "./model";
import { GridOptions } from "ag-grid-community";

const props = defineProps<{
    table: ITable;
    pid: string;
    grid: GridOptions;
}>();

const emit = defineEmits(["close", "eventEditor"]);
const url = store.getters.getUrl("/lobby");
const $q = useQuasar();

interface IState {
    images: I_ImageRecord[];
    activeImage: string;
    toggle: boolean[];
    partnerSelector: string;
    partnerImages: I_ImageRecord[];
    tgtImages: I_ImageRecord[];
}
const state: IState = reactive({
    loadUrl: "",
    activeImage: "",
    toggle: [],
    images: [],
    partnerImages: [],
    tgtImages: [],
    partnerSelector: "partner",
}) as IState;
markRaw(state);

const table = ref<ITable>(props.table);

const getActiveImageUrl = computed(() => {
    if (state.activeImage) {
        for (let item of state.images) {
            if (item.id == state.activeImage) {
                return item.url;
            }
        }
    }
    return "";
});

function onFileUploaded(imageRecord: I_ImageRecord) {
    for (let lang of store.state.enabledLang) {
        imageRecord.meta[lang.id] = { title: "", label: "", message: "", what: "" };
    }
    state.images.unshift(imageRecord);
    setToggle();
}

const pid = computed(() => {
    return state.partnerSelector == "tgt" ? "tgt" : props.pid;
});

onBeforeMount(async () => {
    await loadImages();
});

async function loadImages() {
    const images: I_ImageRecord[] = await useFetch(`${url}/lobbybgimage/${pid.value}`, { method: "GET" });
    state.images = images;
    if (images) {
        if (pid.value == "tgt") {
            state.tgtImages = images;
        } else {
            state.partnerImages = images;
        }
    } else {
    }
    for (const item of state.images) {
        if (item.url == table.value.customization.lobby_background) {
            state.activeImage = item.id;
        }
    }
    setToggle();
}

async function onSetNewBackground(index) {
    let imageUrl = "";
    if (state.toggle[index]) {
        state.activeImage = state.images[index].id;
        imageUrl = state.images[index].url;
    }
    await setNewBackground(imageUrl);
    setToggle();
}

async function setNewBackground(imageUrl: string) {
    await useFetch(`${url}/lobbybgimage`, { method: "PUT", body: { tid: table.value.tid, url: imageUrl } });
    table.value.customization.lobby_background = imageUrl;
    props.grid.api?.applyTransaction({ update: [table.value] });
    if (imageUrl == "") {
        state.activeImage = "";
    }
}

function setToggle() {
    state.toggle.length = 0;
    state.images.map((item) => {
        if (item.id == state.activeImage) {
            state.toggle.push(true);
        } else {
            state.toggle.push(false);
        }
    });
}

async function onDelete(image: IImageRecord) {
    // remove active if it is deleted
    if (state.activeImage == image.id) {
        setNewBackground("");
    }

    const resp = await useFetch(`${url}/lobbybgimage`, { method: "DELETE", body: { file: image.file } });
    if (resp) {
        // state.tgtImages = state.tgtImages.filter((image: IImageRecord) => {
        //     return image.id != imageId;
        // })
        // state.partnerImages = state.partnerImages.filter((image: IImageRecord) => {
        //     return image.id != imageId;
        // })
        state.images = state.images.filter((_image: IImageRecord) => {
            return _image.id != image.id;
        });
    }
}

function partnerChanged(partner: string) {
    if (partner == "tgt" && state.tgtImages.length == 0) {
        loadImages();
        return;
    }
    if (partner == "partner" && state.partnerImages.length == 0) {
        loadImages();
        return;
    }

    state.images = partner == "tgt" ? state.tgtImages : state.partnerImages;
    state.toggle.length = 0;
    window.setTimeout(() => {
        setToggle();
    }, 100);
}
</script>
<style></style>
