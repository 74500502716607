<template>
    <div id="game-editor" class="game-editor">
        <q-select
            v-model="value"
            :options="options"
            multiple
            menu-anchor="center middle"
            label="Game selector"
            emit-value
            map-options
            options-dense
            for="game-editor"
            @popup-hide="hide"
        >
            <template v-slot:option="{ itemProps, opt, selected, toggleOption }">
                <q-item v-bind="itemProps" dense>
                    <q-item-section>
                        <q-item-label v-html="opt.label"></q-item-label>
                    </q-item-section>
                    <q-item-section side>
                        <q-toggle :model-value="selected" @update:model-value="toggleOption(opt)"></q-toggle>
                    </q-item-section>
                </q-item>
            </template>
        </q-select>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, onBeforeMount, toRef, computed, nextTick } from "vue";
import { store } from "@/store";

export default defineComponent({
    props: {},
    components: {},
    setup(props, { attrs, slots, emit }) {
        const value = ref(props["params"].value);

        onBeforeMount(() => {
            console.log(props["params"]);
            // value.value = value.value.data.games;
        });

        const options = computed(() => {
            return store.state.games;
        });

        const isPopup = () => {
            return false;
        };
        const getValue = () => {
            return value.value;
        };

        const hide = (ev: Event) => {
            nextTick(() => {
                props["params"].stopEditing();
            });
        };

        return {
            hide,
            isPopup,
            getValue,
            options,
            value,
        };
    },
});

// const props = defineProps<{
//     params: any;
// }>();

// let value = ref(props.params.value);

// // let params: any = toRef(props, "params");

// const options = computed(() => {
//     return store.state.games;
// });

// function isPopup() {
//     return false;
// }
// const getValue = () => {
//     return value.value;
// };

// function getGame(gameId) {
//     const games = store.state.games;
//     for (let i = 0; i < games.length; i++) {
//         if (games[i].value == gameId) {
//             return games[i].value;
//         }
//     }
// }

// onBeforeMount(() => {
//     // value.value = params.value.data.games;
// });
</script>
<style>
#game-editor {
    max-width: 400px;
    max-height: 300px;
}
</style>
