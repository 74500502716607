<template>
    <q-page>
        <StatHeader @loadData="loadData" :multiple="true" :partnersVisible="true"></StatHeader>
        <AgCharts :options="chartOptions" style="height: 600px; margin-top: 50px"></AgCharts>
    </q-page>
</template>

<script setup lang="ts">
import { ref, Ref } from "vue";
import { AgCharts } from "ag-charts-vue3";
import { utils } from "@/tools/utils";
import { AgChartOptions } from "ag-charts-community";
import useFetch from "@/tools/fetch";
import { store } from "@/store";
import StatHeader from "../StatHeader.vue";

type T_HourlyPlayingTimeResponse = {
    pid?: string;
    hour: number;
    gameTime: number;
    partnerName: string;
    size?: number;
    h?: string;
};

const url = store.getters.getUrl("/stat/partners");

const chartOptions = ref();

async function loadData(pids: string | string[], start: string, stop: string) {
    const data: T_HourlyPlayingTimeResponse[] = await useFetch(`${url}/hourlyplayingtime`, { method: "POST", body: { pids, start, stop } });
    setChart(data);
}

function setChart(statResponse: T_HourlyPlayingTimeResponse[]) {
    const data = sortHour(statResponse);
    chartOptions.value = {
        autoSize: true,
        data: data,
        title: {
            text: "Playingtime distribution",
            fontSize: 18,
        },
        subtitle: {
            text: "",
        },
        series: [
            {
                type: "bubble",
                xKey: "h",
                xName: "Hour",
                yKey: "partnerName",
                yName: "Partner",
                sizeKey: "gameTime",
                sizeName: "Playing Time",

                shape: "circle",
                size: 5,
                maxSize: 50,
                //domain: [0, 50],
                fill: "rgba(123,145,222,0.71)",
                stroke: "#56659b",

                tooltip: {
                    renderer: function (params) {
                        // console.log(params);
                        return {
                            title: params.datum.partnerName,
                            content: `${utils.secToHour(params.datum.gameTime)}`,
                        };
                    },
                },
            },
        ],
        axes: [
            {
                position: "bottom",
                type: "category",
                gridStyle: [
                    {
                        stroke: "rgba(0,0,0,0.2)",
                        lineDash: [0, 5, 0],
                    },
                ],
                tick: {
                    color: "black",
                },
                line: {
                    color: undefined,
                },
            },
            {
                position: "left",
                type: "category",
                gridStyle: [],
                tick: {
                    color: "black",
                },
                line: {
                    color: undefined,
                },
            },
        ],

        legend: {
            enabled: false,
        },
    };
}

function sortHour(statResponse: T_HourlyPlayingTimeResponse[]): T_HourlyPlayingTimeResponse[] {
    return statResponse
        .map((item) => {
            return Object.assign(item, { h: `${item.hour}h` });
        })
        .sort((a: T_HourlyPlayingTimeResponse, b: T_HourlyPlayingTimeResponse) => {
            if (a.hour == b.hour) return 0;
            return a.hour < b.hour ? -1 : 1;
        });
}
</script>
<style></style>
