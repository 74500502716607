<template>
    <q-card class="items-center justify-start" style="height: auto; min-width: 400px; max-width: 80vw">
        <q-card-section class="text-h6 bg-primary text-white">
            <div class="row justify-end">
                <q-btn flat label="Add manager" @click="addManager()" />
            </div>
        </q-card-section>
        <q-card-section v-for="item in managers" class="column q-gutter-md">
            <q-input class="col" v-model="item.username" label="Username" />
            <q-input class="col" v-model="item.password" label="Password" />
            <!-- <q-select use-chips v-model="tables" :options="allTables" map-options emit-value label="Tables" multiple class="col" /> -->
            <q-select class="col" outlined dense v-model="item.role" :options="ROLES" label="Role" />
            <q-select class="col" outlined dense use-chips v-model="item.tables" :options="tableOptions" map-options emit-value label="Tables" multiple />
            <div class="row justify-end q-pt-md text-primary">
                <q-icon @click="onDelete(item)" size="24px" name="delete" />
                <q-icon @click="onSave(item)" size="24px" name="save" class="q-ml-md" />
            </div>
        </q-card-section>
    </q-card>
</template>
<script setup lang="ts">
import { store } from "@/store";
import useFetch from "@/tools/fetch";
import { Notify } from "quasar";
import { onMounted, reactive, computed, ref } from "vue";
import { IPartner } from "../model";

type TMap = {
    [key: string]: string;
};
interface IManagerResponse {
    managers: IManager[];
    tables: TMap;
}

interface IManager {
    username: string;
    password: string;
    role: string;
    mid?: string;
    tables: string[];
}

const ROLES = ["manager", "payment"];

const props = defineProps<{
    partner: IPartner;
}>();

const managers = ref<IManager[]>(props.partner["managers"] || []);
const tableOptions = ref<{ label: string; value: string }[]>([]);

const state = reactive({});

const url = store.getters.getUrl();

function addManager() {
    managers.value.push({
        username: "",
        password: "",
        role: "",
        mid: "",
        tables: [],
    });
}

onMounted(async () => {
    const resp = (await useFetch(`${url}/partner/manager/${props.partner.id}`, {
        method: "GET",
    })) as IManagerResponse;
    if (resp) {
        managers.value = resp.managers || [];
        tableOptions.value = [];
        for (const [key, value] of Object.entries(resp.tables)) {
            tableOptions.value.push({ label: value, value: key });
        }
    }
});

async function onDelete(manager: IManager) {
    if (!manager.mid) {
        managers.value = managers.value.filter((item: IManager) => item.mid);
        return;
    }
    const resp = await useFetch(`${url}/partner/manager/${props.partner.id}?mid=${manager.mid}`, {
        method: "DELETE",
    });
    if (resp) {
        managers.value = managers.value.filter((_manager: IManager) => {
            return _manager.mid != manager.mid;
        });
        Notify.create({ type: "positive", message: "Data has been saved." });
    }
}

async function onSave(manager: IManager) {
    const resp = await useFetch(`${store.getters.getUrl()}/partner/manager/${props.partner.id}`, {
        method: "PUT",
        body: manager,
    });
    if (resp) {
        managers.value = resp.managers || [];
        Notify.create({ type: "positive", message: "Data has been saved." });
    }
}
</script>
<style></style>
