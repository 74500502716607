<template>
    <q-page>
        <StatHeader @loadData="loadData" :multiple="true" :partnersVisible="false" :totalTime="totalTime"></StatHeader>
        <AgCharts :options="chartOptions" style="height: 700px; margin-top: 50px"></AgCharts>
    </q-page>
</template>

<script setup lang="ts">
import { ref, Ref } from "vue";
import { AgCharts } from "ag-charts-vue3";
import { utils } from "@/tools/utils";
import { AgCartesianChartOptions } from "ag-charts-community";
import useFetch from "@/tools/fetch";
import { store } from "@/store";
import StatHeader from "../StatHeader.vue";

interface IGameStat {
    gameName: string;
    gameTime: number;
}

interface IGamesPlayingtimeByPartners {
    partnerName: string;
    gameStat: IGameStat[];
}

interface IChartData {
    partnerName: string;
    gameName: string;
    gameTime: number;
}

const url = store.getters.getUrl("/stat");

const chartOptions: Ref<AgCartesianChartOptions> = ref({});
const totalTime = ref("0");

async function loadData(pids: string | string[], start: string, stop: string) {
    const data: IGamesPlayingtimeByPartners[] = await useFetch(`${url}/gamesplayingtime`, { method: "POST", body: { start, stop } });
    let total = 0;
    for (const partner of data) {
        for (const game of partner.gameStat) {
            total += game.gameTime;
        }
    }

    // for (const record of data.value) {
    //     total += record.gameTime;
    // }
    totalTime.value = utils.formatGameTime(total);

    setChart(data);
}

function setChart(statResponse: IGamesPlayingtimeByPartners[]) {
    const data = setChartData(statResponse);
    chartOptions.value = {
        data: data,
        title: {
            text: "Games stat",
            fontSize: 18,
        },
        subtitle: {
            text: "",
        },
        series: [
            {
                type: "bubble",
                xKey: "partnerName",
                xName: "Partner",
                yKey: "gameName",
                yName: "Game",
                sizeKey: "gameTime",
                sizeName: "Playing Time",

                shape: "circle",
                size: 3,
                maxSize: 50,
                fill: "rgba(123,145,222,0.71)",
                stroke: "#56659b",

                tooltip: {
                    renderer: function (params: any) {
                        console.log(params);
                        return {
                            title: params.datum.gameName,
                            content: `${utils.secToHour(params.datum.gameTime)}`,
                        };
                    },
                },
            },
        ],
        axes: [
            {
                position: "bottom",
                type: "category",
                gridLine: {
                    style: [{ stroke: "rgba(0,0,0,0.2)" }, { lineDash: [0, 5, 0] }],
                },
                tick: {
                    stroke: "black",
                },
                line: {
                    stroke: undefined,
                },
            },
            {
                position: "left",
                type: "category",

                tick: {
                    stroke: "black",
                },
                line: {
                    stroke: undefined,
                },
            },
        ],

        legend: {
            enabled: false,
        },
    };
}

function setChartData(data: IGamesPlayingtimeByPartners[]): IChartData[] {
    const sorted = data.sort((a: IGamesPlayingtimeByPartners, b: IGamesPlayingtimeByPartners) => {
        if (a.partnerName == b.partnerName) return 0;
        return a.partnerName < b.partnerName ? -1 : 1;
    });

    const chartData: IChartData[] = [];
    for (let item of sorted) {
        const gameStat = item.gameStat.sort((a: IGameStat, b: IGameStat) => {
            if (a.gameTime == b.gameTime) return 0;
            return a.gameTime < b.gameTime ? 1 : -1;
        });
        console.log("%s: %o", item.partnerName, gameStat);
        for (let game of gameStat) {
            chartData.push({ partnerName: item.partnerName, gameName: game.gameName, gameTime: game.gameTime });
        }
    }
    return chartData;
}
</script>
<style></style>
