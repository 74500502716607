import Actions from "../../components/action.vue";
import HeaderCheckbox from "@/components/header-checkbox.js";
import GameEditor from "@/components/GameEditor.vue";
import LangEditor from "@/components/LangEditor.vue";
import GameRenderer from "@/components/GameRenderer.vue";
import { secToHour } from "@/components/utils";
import Checkbox from "@/components/checkbox.js";
import { GridOptions, ColDef, ValueSetterParams, ValueGetterParams } from "ag-grid-community";

export function gridOptions(): GridOptions {
    return {
        pagination: true,
        paginationPageSize: 500,
        context: {
            actionCallback: null,
            actionIcons: [
                {
                    icon: "edit",
                    tooltip: "Edit",
                },
                {
                    icon: "save",
                    tooltip: "Save",
                },
                {
                    icon: "delete",
                    tooltip: "Delete",
                },
            ],
        },
        rowSelection: "single",
        defaultColDef: {
            // allow every column to be aggregated
            enableValue: true,
            // allow every column to be grouped
            enableRowGroup: true,
            // allow every column to be pivoted
            enablePivot: true,
            resizable: true,
            sortable: true,
            filter: true,
            wrapText: true,

            autoHeight: true,
        },
        sideBar: {
            toolPanels: [
                {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel",
                },
                {
                    id: "filters",
                    labelDefault: "Filters",
                    labelKey: "filters",
                    iconKey: "filter",
                    toolPanel: "agFiltersToolPanel",
                },
            ],
        },

        onGridReady: () => {},
        components: {
            checkbox: Checkbox,
            gameRenderer: GameRenderer,
        },
        getRowId: function (params) {
            return params.data.id;
        },
        rowData: [],
        columnDefs: columDefs(),
        enableCellChangeFlash: true,
        // refreshCells: true
    };
}

function columDefs(): ColDef[] {
    return [
        {
            headerName: "ID",
            field: "id",
            width: 200,
            editable: true,
            hide: true,
        },
        {
            headerName: "Name",
            field: "name",
            width: 180,
            filter: "text",
            sort: "asc",
        },
        {
            headerName: "Email",
            field: "email",
            width: 200,
        },
        {
            headerName: "Hotel",
            field: "hotel",
            width: 200,
        },
        {
            headerName: "Question",
            field: "question",
            width: 300,
        },
        {
            headerName: "Date",
            field: "date",
            width: 150,
            // editable: true,
        },

        {
            headerName: "Comment",
            field: "comment",
            width: 300,
            cellEditorParams: {
                maxLength: 1000,
            },
            cellStyle: {
                "white-space": "normal",
            },
            cellEditor: "agLargeTextCellEditor",
            suppressSizeToFit: true,
            editable: true,
            hide: true,
        },

        // {
        //     headerName: "Actions",
        //     field: "action",
        //     width: 120,
        //     cellRenderer: Actions,
        //     suppressMenu: true,
        //     pinned: "right",
        //     suppressSizeToFit: true,
        // },
    ];
}
