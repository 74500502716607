<template>
    <q-page class="q-pa-md">
        <div class="row items-end justify-between">
            <div class="row items-end q-gutter-x-xl">
                <q-input v-model="state.quickFilter" @update:model-value="setQuickFilter" placeholder="Filter" />
                <q-btn dense label="Send report" flat color="primary" @click="sendStatistics" />
            </div>
            <div class="row justify-between q-gutter-x-md">
                <q-btn class="col" color="primary" label="New" @click="addRow()" />
                <q-btn class="col" color="primary" label="Load" @click="load()" />
                <q-btn color="primary items-end" label="Excel" @click="exportExcel()" />
            </div>
        </div>
        <AgGridVue style="width: 100%; height: 80vh; margin: 20px 0" class="ag-theme-balham" :gridOptions="state.grid"></AgGridVue>
        <q-dialog
            style="max-height: 80vh"
            v-model="state.dialogVisible"
            transition-show="slide-up"
            transition-hide="slide-down"
            v-on:close="state.dialogVisible = false"
        >
            <component :is="dialog" v-on:dialogEvent="dialogEvent" :partner="selectedRow()" v-on:eventEditor="eventEditor($event)"></component>
        </q-dialog>
    </q-page>
</template>

<script setup lang="ts">
import { reactive, onBeforeMount, shallowRef, ref } from "vue";
import { AgGridVue } from "ag-grid-vue3";
import { CellValueChangedEvent, GridOptions, RowClickedEvent, CellClickedEvent } from "ag-grid-community";
import { gridOptions } from "./grid-config";
import { store, T_Game } from "@/store";
import { useQuasar } from "quasar";
import useFetch from "@/tools/fetch";
import DeleteDialog from "@/components/DeleteDialog.vue";
import Puzzle from "./editors/Puzzle.vue";
import router from "@/router";
import { I_GridRow, IPartner, IEndpoint } from "./model";
import PartnerData from "./editors/PartnerData.vue";
import ActionSelector from "./editors/ActionSelector.vue";
import Manager from "./editors/Manager.vue";
import Endpoint from "./editors/Endpoint.vue";
import CreditFill from "./editors/creditfillup.vue";
import { action } from "./partner-action";
import { T_Partner, T_Endpoint } from "@/model/partner";
import PartnerSelector from "../PartnerTables/PartnerSelector.vue";

type T_State = {
    grid: GridOptions;
    quickFilter: string;
    dialogVisible: boolean;
};

type T_EditorEvent = {
    action: string;
    timeBudget?: number;
    endpoint?: IEndpoint;
};

interface IDialogEvent {
    action: string;
    data?: any;
}

const BASE_ID = "1111";
// const DeleteDialog = defineAsyncComponent(() => import("./editors/DeleteDialog.vue"));

// const dialog = shallowRef<typeof ActionSelector | typeof DeleteDialog | typeof Puzzle | typeof PartnerData | typeof Manager | null | string>(null);
const dialog = shallowRef<any>(null);

const grid: GridOptions = gridOptions();
grid.context.actionCallback = actionClick;

grid.getContextMenuItems = (params) => {
    return [
        {
            name: "Games",
            subMenu: [
                {
                    name: "Puzzle upload",
                    action: () => {
                        if (selectedRow()) {
                            dialog.value = Puzzle;
                            state.dialogVisible = true;
                        } else {
                            $q.notify({ position: "top", type: "negative", message: "Select a partner" });
                        }
                    },
                },
            ],
        },
    ];
};
const $q = useQuasar();

const url = store.getters.getUrl();

const state: T_State = reactive({ grid: grid, quickFilter: "", dialogVisible: false }) as T_State;

grid.onGridReady = () => {
    grid.onRowClicked = (event: RowClickedEvent) => {};
    grid.onCellValueChanged = (event: CellValueChangedEvent) => {
        if (event.data.id == BASE_ID) return;

        const field = event.colDef.field as string;
        let value: any;
        switch (field) {
            case "rooms":
                value = Number(event.newValue);
                break;
            case "office.cloudDate.firstDay":
            case "office.cloudDate.lastDay":
                const dateReg = /^\d{4}[-]\d{2}[-]\d{2}$/;
                value = event.newValue;
                if (!value.match(dateReg)) {
                    $q.notify({ type: "negative", color: "red", message: "Invalid date format (2023-01-01)" });
                    event.data[field] = event.oldValue;
                    grid.api?.applyTransaction({ update: [event.data] });
                    return;
                }

                break;
            default:
                value = event.newValue;
        }
        action.saveSingleItem(event.data.id, field, value);
    };
    // grid.onCellMouseOver = cellMouseOver;
    grid.onCellClicked = (event: CellClickedEvent) => {
        switch (event.colDef.field) {
            case "tables":
                router.push(`/tables?pid=${event.data.id}`);
                break;
            case "timeBudget":
                dialog.value = CreditFill;
                state.dialogVisible = true;
                break;
        }
    };
    load();
};

function setQuickFilter() {
    grid.api?.setQuickFilter(state.quickFilter);
}

onBeforeMount(async () => {
    await loadGames();
});

async function load() {
    grid.api?.setRowData([]);
    const partners: T_Partner[] = await useFetch(`${url}/partner`, { method: "GET" });
    if (partners) {
        store.commit("partners", partners);
        grid.api?.applyTransaction({ add: partners });
    } else {
    }
}

async function loadGames() {
    const games: T_Game[] = await useFetch(`${url}/games`, { method: "GET" });
    if (games) {
        store.commit("games", games);
    } else {
    }
}

function dialogEvent(event: IDialogEvent) {
    console.log("Dialog: ", event);
    switch (event.action) {
        case "delete":
            if (dialog.value === DeleteDialog) {
                const row = selectedRow();
                if (row) {
                    action.deleteRecord(grid, row.id);
                }
            }
            state.dialogVisible == false;
            dialog.value = null;
            break;
        case "editBusinessData":
            state.dialogVisible = true;
            dialog.value = PartnerData;
            break;
        case "editManager":
            state.dialogVisible = true;
            dialog.value = Manager;
            break;
        case "table":
            const row = selectedRow();
            if (row) {
                state.dialogVisible = false;
                dialog.value = null;
                router.push(`/table/${row.id}`);
            }

            break;
        case "endpoint":
            state.dialogVisible = true;
            dialog.value = Endpoint;
            break;
    }
}

function eventEditor(event: T_EditorEvent) {
    const row = selectedRow() as T_Partner;

    switch (event.action) {
        case "timeBudget":
            if (row) {
                row.timeBudget = event.timeBudget as number;
            }
            break;
        case "endpoint":
            if (row) {
                row.endpoint = event.endpoint as T_Endpoint;
            }
            break;
    }
    grid.api?.applyTransaction({ update: [selectedRow] });
}

function addRow() {
    grid.api?.applyTransaction({ remove: [{ id: "1111" }] });
    grid.columnApi!.setColumnsVisible(["private]"], true);
    grid.columnApi!.applyColumnState({
        defaultState: { sort: null },
    });

    grid.api?.applyTransaction({
        add: [
            {
                id: BASE_ID,
                assetsId: "",
                partnerName: "",
                partnerCode: "",
                rooms: 0,
                active: true,
                games: store.getters.games,
            },
        ],
        addIndex: 0,
    });
}

function selectedRow(): T_Partner | null {
    if (grid.api?.getSelectedNodes().length) {
        console.log("selected: ", grid.api?.getSelectedNodes()[0].data);
        return grid.api?.getSelectedNodes()[0].data;
    }
    return null;
}

async function actionClick(event: string, data: any) {
    console.log(event, data);

    switch (event) {
        case "edit":
            state.dialogVisible = true;
            dialog.value = ActionSelector;

            break;
        case "save":
            await action.save(grid, data);
            break;

        case "delete":
            const selected = selectedRow();
            if (selected) {
                if (selected.id == BASE_ID) {
                    grid.api?.applyTransaction({ remove: [{ id: BASE_ID }] });
                    grid.columnApi!.applyColumnState({
                        state: [{ colId: "partnerName", sort: "asc" }],
                        defaultState: { sort: null },
                    });
                } else {
                    dialog.value = DeleteDialog;
                    state.dialogVisible = true;
                }
            }
            break;
    }
}

async function sendStatistics() {}

function exportExcel() {
    grid.api?.exportDataAsExcel();
}
</script>
<style></style>
