<template>
    <div class="row items-start justify-between" style="width: 100%">
        <div class="col-4">
            <q-file
                ref="imageUploader"
                clearable
                filled
                bottom-slots
                color="primary"
                v-model="state.file"
                label="Upload an image"
                accept=".jpg, .png, .webp, .jfif, .jpeg"
                @update:modelValue="upload"
            >
                <template v-slot:after>
                    <!-- <q-btn round dense flat icon="send" @click="upload(state.file)" color="primary" /> -->
                </template>
            </q-file>
        </div>
        <!-- <q-btn color="primary" label="Close" @click="emit('close')" /> -->
        <q-btn icon="close" flat round dense v-close-popup />
    </div>
</template>
<script setup lang="ts">
import { onMounted, reactive, computed, ref, toRef } from "vue";
import { useQuasar } from "quasar";
import router from "@/router";
import useFetch from "@/tools/fetch";
import { store } from "@/store";

type T_State = {
    file: File | null;
};

const props = defineProps<{
    pid: string;
    url: string;
}>();

const emit = defineEmits(["fileUploaded", "close"]);

const $q = useQuasar();
const state: T_State = reactive({ file: null }) as T_State;

async function upload(file) {
    // if (state.file == null) {
    //     return;
    // }
    if (!checkFileSize(file)) {
        return;
    }
    let formData = new FormData();
    formData.append("file", file, `${file.name}`);
    formData.append("pid", props.pid);
    const res = await useFetch(
        `${props.url}`,
        { method: "POST", body: formData, headers: new Headers({ [store.state.tokenHeader]: store.state.authToken }) },
        false
    );
    state.file = null;
    // ref("imageUploader").removeFile(file);
    if (res) {
        emit("fileUploaded", res);
    }
}

function checkFileSize(file): boolean {
    if (state.file == null) {
        return false;
    }
    let img = new Image();
    img.src = window.URL.createObjectURL(file);
    img.onload = () => {
        if ((img.width == 1920 && img.height == 1080) || (img.width == 1080 && img.height == 1920)) {
            console.log("ok");
            state.file = file;
        } else {
            $q.notify({ position: "top", type: "negative", message: `Invalid file size. Must be 1920x1080 or 1080x1920. Your: ${img.width}x${img.height}` });
            return false;
            // state.file = null;
        }
    };
    return true;
}
</script>
<style></style>
