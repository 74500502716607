<template>
    <q-card class="items-center justify-start q-pa-md" style="min-width: 800px; max-width: 80vw; height: auto">
        <ImageUpload :pid="props.partner.id" :url="puzzleUrl" v-on:fileUploaded="onFileUploaded" v-on:close="emit('close')"></ImageUpload>

        <div class="col row q-gutter-md">
            <div class="column items-center" v-for="(item, index) in state.imageList" :key="index">
                <div class="col q-gutter-md">
                    <q-img style="width: 220px" :src="getImageUrl(item)"></q-img>
                </div>
                <div class="col jusify-around col-2 items-center">
                    <q-icon name="delete" color="red" style="font-size: 2em" @click="onDelete(item)"></q-icon>
                </div>
            </div>
        </div>
    </q-card>
</template>
<script setup lang="ts">
import { onMounted, reactive, markRaw } from "vue";
import { useQuasar } from "quasar";
import useFetch from "@/tools/fetch";
import { store } from "@/store";
import { IPartner } from "../model";

type T_State = {
    imageList: string[];
    loadUrl: string;
};

const props = defineProps<{
    partner: IPartner;
}>();

const emit = defineEmits(["close"]);

const $q = useQuasar();
const state: T_State = reactive({ imageList: [], loadUrl: "" }) as T_State;
markRaw(state);

const puzzleUrl = `${store.getters.getUrl()}/game/puzzle`;

function getImageUrl(imagePath: string) {
    return location.hostname == "localhost"
        ? `/customization/${props.partner.assetsId}/puzzle/high_res/${imagePath}`
        : `${state.loadUrl}/high_res/${imagePath}`;
}

async function onDelete(imageName: string) {
    const resp = await useFetch(`${puzzleUrl}`, { method: "DELETE", body: { imageName, pid: props.partner.id } });
    if (resp) {
        state.imageList = state.imageList.filter((item) => item != imageName);
    }
}

function onFileUploaded(data: any) {
    if (data?.fileName) {
        // loadImages();
        state.imageList.push(data.fileName);
    }
}

async function loadImages() {
    const resp: { images: string[]; url: string } = await useFetch(`${puzzleUrl}/${props.partner.id}`, { method: "GET" });
    if (resp) {
        state.imageList = resp.images;
        state.loadUrl = resp.url;
    }
}

onMounted(async () => {
    await loadImages();
});
</script>
<style></style>
