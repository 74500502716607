<template>
    <div id="lang-editor">
        <div v-for="(item, index) in langs" :key="index" class="column q-pa-md" style="min-width: 300px">
            <q-input class="col" v-model="lang[item]" filled autogrow type="textarea" :label="item" dense stack-label @keydown="onKeypress($event, item)" />
        </div>
    </div>
</template>

<script lang="ts">
import { ref, onBeforeMount, toRef, computed, defineComponent } from "vue";
import { store } from "@/store";

export default defineComponent({
    props: ["params"],
    setup(props) {
        const langs = store.state.langs;
        let lang = ref({});

        function getValue() {
            return lang.value;
        }

        function onKeypress(event: KeyboardEvent, currLang: string) {
            if (event.key == "Enter" && event.shiftKey) {
                console.log(lang.value[currLang]);
                lang.value[currLang] += "\n";
                event.stopPropagation();
                event.preventDefault();
            }
        }

        onBeforeMount(() => {
            const col = props.params.colDef.field;
            lang.value = props.params.data[col] ?? {};
        });

        return {
            getValue,
            lang,
            langs,
            onKeypress,
        };
    },
});
</script>
