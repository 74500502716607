<template>
    <q-card class="items-center justify-start" style="height: auto; min-width: 400px; max-width: 80vw">
        <q-card-section class="text-h6 bg-primary text-white">
            <div class="row justify-start">
                <q-btn flat label="Set endpoint" />
            </div>
        </q-card-section>
        <q-card-section class="column q-gutter-md">
            <q-input class="col" v-model="endpoint.lobby" label="Lobby" clearable />
            <q-input class="col" v-model="endpoint.game" label="Game" clearable />
            <q-input class="col" v-model="endpoint.table" label="Table" clearable />
            <!-- <q-select use-chips v-model="tables" :options="allTables" map-options emit-value label="Tables" multiple class="col" /> -->

            <div class="row justify-end q-pt-md text-primary">
                <q-icon @click="onSave()" size="24px" name="save" class="q-ml-md" />
            </div>
        </q-card-section>
    </q-card>
</template>
<script setup lang="ts">
import { store } from "@/store";
import useFetch from "@/tools/fetch";
import { Notify } from "quasar";
import { ref } from "vue";
import { IEndpoint, IPartner } from "../model";

type TMap = {
    [key: string]: string;
};

const props = defineProps<{
    partner: IPartner;
}>();

const endpoint = ref<IEndpoint>(props.partner["endpoint"]);

const emit = defineEmits(["eventEditor"]);

async function onSave() {
    const ep = endpoint.value as IEndpoint;
    // for (let [k, v] of Object.entries(ep)) {
    //     if (v == null) {
    //         ep[k] = "";
    //     }
    // }
    const body = { id: props.partner.id, key: "endpoint", value: ep };

    const resp = await useFetch(`${store.getters.getUrl()}/partner`, { method: "PUT", body });

    if (resp) {
        Notify.create({ type: "positive", message: "Data has been saved." });
        emit("eventEditor", { action: "endpoint", endpoint });
    }
}
</script>
<style></style>
