<template>
    <q-page>
        <StatHeader @loadData="loadData" :multiple="true" :partnersVisible="true" :totalTime="totalTime"></StatHeader>
        <div class="col">
            <LineChart :playingTimeData="playingTimeData"></LineChart>
        </div>
    </q-page>
</template>

<script lang="ts" setup>
import { ref, Ref } from "vue";

import { store } from "@/store";
import StatHeader from "../StatHeaderRooms.vue";

import LineChart, { T_PlayingTimeResponse } from "./LineChart.vue";


import useFetch from "@/tools/fetch";
import { utils } from "@/tools/utils";

const url = store.getters.getUrl("/stat/partners");

const playingTimeData: Ref<T_PlayingTimeResponse[]> = ref([]);
const totalTime = ref("0");

async function loadData(pids: string | string[], start: string, stop: string, rooms: number[]) {
    // console.log(pid, start, stop);

    playingTimeData.value = await useFetch(`${url}/linechart`, { method: "POST", body: { pids, start, stop, rooms } });

    let total = 0;
    for (const record of playingTimeData.value) {
        total += record.gameTime;
    }
    totalTime.value = utils.formatGameTime(total);
}
</script>
<style></style>
