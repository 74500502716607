import Checkbox from "@/components/checkbox.js";
import { GridOptions, ColDef, GetRowIdParams } from "ag-grid-community";
import Actions from "../../components/action.vue";

export function gridOptions(): GridOptions {
    return {
        pagination: true,
        paginationPageSize: 500,
        context: {
            actionCallback: null,
            actionIcons: [
                {
                    icon: "terminal",
                    tooltip: "Terminal",
                },
            ],
        },

        defaultColDef: {
            // allow every column to be aggregated
            enableValue: true,
            // allow every column to be grouped
            enableRowGroup: true,
            // allow every column to be pivoted
            enablePivot: true,
            resizable: true,
            sortable: true,
            filter: true,
        },
        sideBar: {
            toolPanels: [
                {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel",
                },
                {
                    id: "filters",
                    labelDefault: "Filters",
                    labelKey: "filters",
                    iconKey: "filter",
                    toolPanel: "agFiltersToolPanel",
                },
            ],
        },

        onGridReady: () => {},
        components: {
            checkbox: Checkbox,
        },
        getRowId: function (params: GetRowIdParams) {
            return params.data.tid;
        },
        rowData: [],
        columnDefs: columDefs(),
        enableCellChangeFlash: true,
        rowSelection: "multiple",
        // refreshCells: true
    };
}

function columDefs(): ColDef[] {
    return [
        {
            colId: "checkbox",
            maxWidth: 50,
            checkboxSelection: true,
            suppressMenu: true,
            headerCheckboxSelection: true,
        },
        {
            headerName: "Tid",
            field: "tid",
            width: 200,
            hide: true,
            editable: true,
        },
        {
            headerName: "Partner",
            field: "partnerName",
            width: 200,
            filter: "text",
            sort: "asc",
        },
        {
            headerName: "Table",
            field: "tableName",
            width: 180,
        },
        {
            headerName: "Status",
            field: "status",
            width: 150,
        },
        {
            headerName: "Host",
            field: "host",
            width: 180,
            hide: true,
        },
        {
            headerName: "Ip",
            field: "ip",
            width: 180,
        },
        {
            headerName: "Port",
            field: "port",
            width: 130,
        },
        {
            headerName: "Version",
            field: "version",
            width: 130,
        },
        {
            headerName: "Update",
            field: "flags.updateEnabled",
            width: 100,
            editable: true,
            cellRenderer: "checkbox",
        },
        {
            headerName: "Log enabled",
            field: "flags.logEnabled",
            width: 100,
            editable: true,
            cellRenderer: "checkbox",
        },
        {
            headerName: "Touchguard",
            field: "flags.touchGuardEnabled",
            width: 100,
            editable: true,
            cellRenderer: "checkbox",
        },
        {
            headerName: "Actions",
            field: "action",
            // width: 160,
            cellRenderer: Actions,
            suppressMenu: true,
            pinned: "right",
            // suppressSizeToFit: true,
        },
        // {
        //     headerName: "Online",
        //     field: "online",
        //     width: 180,
        //     cellStyle: (params) => {
        //         return params.value ? { color: "green", fontWeight: "bold" } : { color: "red", fontWeight: "normal" };
        //     },
        // },
    ];
}
