<template>
    <AgCharts :options="chartOptions" style="height: 300px"></AgCharts>
</template>

<script lang="ts" setup>
import { ref, Ref, defineProps, watch, toRef } from "vue";
import { AgCharts } from "ag-charts-vue3";
import { utils } from "@/tools/utils";
import { AgCartesianChartOptions, AgCartesianSeriesOptions } from "ag-charts-community";
import { store } from "@/store";

export type T_PlayingTimeResponse = {
    tid: string;
    pid: string;
    year: string;
    month: string;
    day: string;
    gameTime: number;
    tableName: string;
    partnerName: string;
    date: string;
};

type T_Group = {
    [key: number]: {};
};

type T_Props = {
    playingTimeData: T_PlayingTimeResponse[];
};
const props = defineProps<T_Props>();

const chartOptions: Ref<AgCartesianChartOptions> = ref({});
const tableNames: Set<string> = new Set();
const partnerNames: { pid?: string; partnerName?: string } = {};

const playingTimeData = toRef(props, "playingTimeData");

watch(playingTimeData, (newValue: T_PlayingTimeResponse[]) => {
    setChart(playingTimeData.value);
});

function setChart(statResponse: T_PlayingTimeResponse[]) {
    const { data, series } = getChartData(statResponse);
    chartOptions.value = {
        // height: 600,
        data,
        // container: document.getElementById("chart"),
        // autoSize: true,
        series,
        axes: [
            {
                type: "category",
                position: "bottom",
                label: {
                    rotation: 30,
                },
            },
            {
                type: "number",
                position: "left",
                label: {
                    formatter: function (params) {
                        return utils.secToHour(params.value) + "h";
                    },
                },
            },
        ],
        legend: {
            enabled: false,
            spacing: 40,
            position: "bottom",
        },
    };
}

function getChartData(statResponse: T_PlayingTimeResponse[]) {
    const data = getData(statResponse);
    const series = getSeries(data);
    return { data, series };
}

function getSeries(data: any[]): AgCartesianSeriesOptions[] {
    const series: AgCartesianSeriesOptions[] = [];

    for (let k of tableNames) {
        series.push({ type: "bar", xKey: "date", yKey: k, yName: k, stacked: true, fill: getColor(k) });
    }

    return series;
}

function getColor(tableName: string) {
    if (tableName in store.state.tableColors) {
        return store.state.tableColors[tableName];
    }
    return store.state.tableColors.Blue;
}

function tooltip() {
    return {
        renderer: function (params) {
            console.log("PARAMS: ", params);
            return {
                title: params.yName,
                content: utils.secToHour(params.datum[params.yName]) + "h",
            };
        },
    };
}

function getData(statResponse: T_PlayingTimeResponse[]): any[] {
    const dates: Set<string> = new Set();

    statResponse.map((item) => {
        const month = String(item.month);
        const day = String(item.day);
        let d = `${item.year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
        dates.add(d);
        item.date = d;
        tableNames.add(item.tableName);
    });

    const sortedDates = [...dates].toSorted((a, b) => {
        const dateA = new Date(a).getTime();
        const dateB = new Date(b).getTime();
        return dateA - dateB;
    });

    const data: {}[] = [];

    for (const date of sortedDates) {
        const record = { date };
        for (let i = 0; i < statResponse.length; i++) {
            const item = statResponse[i];
            if (date == item.date) {
                record[item.tableName] = item.gameTime;
            }
        }
        data.push(record);
    }
    return data;
}
</script>
<style></style>
