import Actions from "../../components/action.vue";
import HeaderCheckbox from "@/components/header-checkbox.js";
import GameEditor from "@/components/GameEditor.vue";
import LangEditor from "@/components/LangEditor.vue";
import GameRenderer from "@/components/GameRenderer.vue";
import { secToHour } from "@/components/utils";
import Checkbox from "@/components/checkbox.js";
import { GridOptions, ColDef, ValueSetterParams, ValueGetterParams, ValueFormatterParams } from "ag-grid-community";

export function gridOptions(): GridOptions {
    return {
        pagination: true,
        paginationPageSize: 500,
        context: {
            actionCallback: null,
            actionIcons: [
                {
                    icon: "edit",
                    tooltip: "Edit",
                },
                {
                    icon: "save",
                    tooltip: "Save",
                },
                {
                    icon: "delete",
                    tooltip: "Delete",
                },
            ],
        },
        rowSelection: "multiple",
        defaultColDef: {
            // allow every column to be aggregated
            enableValue: true,
            // allow every column to be grouped
            enableRowGroup: true,
            // allow every column to be pivoted
            enablePivot: true,
            resizable: true,
            sortable: true,
            filter: true,
        },
        sideBar: {
            toolPanels: [
                {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel",
                },
                {
                    id: "filters",
                    labelDefault: "Filters",
                    labelKey: "filters",
                    iconKey: "filter",
                    toolPanel: "agFiltersToolPanel",
                },
            ],
        },

        onGridReady: () => {},
        components: {
            checkbox: Checkbox,
            gameRenderer: GameRenderer,
        },
        getRowId: function (params) {
            return params.data.id;
        },
        rowData: [],
        columnDefs: columDefs(),
        enableCellChangeFlash: true,
        // refreshCells: true
    };
}

function columDefs(): ColDef[] {
    return [
        {
            colId: "checkbox",
            maxWidth: 50,
            checkboxSelection: true,
            suppressMenu: true,
            headerCheckboxSelection: true,
        },
        {
            headerName: "ID",
            field: "id",
            width: 200,
            editable: true,
            hide: true,
        },
        {
            headerName: "Name",
            field: "partnerName",
            width: 180,
            filter: "text",
            sort: "asc",
            editable: true,
        },
        {
            headerName: "Rooms",
            field: "rooms",
            width: 80,
            editable: true,
        },
        {
            headerName: "City",
            field: "city",
            width: 180,
            editable: true,
        },
        {
            headerName: "Assets id",
            field: "assetsId",
            width: 150,
            // editable: true,
            hide: true,
            editable: (params) => {
                return params.data.id == "1111";
            },
        },
        // {
        //     headerName: "Partner Code",
        //     field: "partnerCode",
        //     width: 180,
        //     editable: true,
        //     hide: true,
        // },
        {
            headerName: "Active",
            field: "active",
            width: 80,
            editable: true,
            cellRenderer: "checkbox",
            hide: true,
        },
        {
            headerName: "Private",
            field: "private",
            width: 80,
            editable: true,
            cellRenderer: "checkbox",
            hide: true,
        },
        // {
        //     headerName: "Username",
        //     field: "username",
        //     width: 150,
        //     editable: true,
        //     hide: true,
        // },

        // {
        //     headerName: "Tables",
        //     field: "tables",
        //     width: 80,
        //     editable: false,
        //     valueGetter: (params: ValueGetterParams) => {
        //         return params.data.tables ? params.data.tables.length : 0;
        //     },
        // },

        {
            headerName: "Games",
            field: "games",
            width: 100,
            editable: true,
            valueFormatter: (params: ValueFormatterParams) => {
                if (params.data.games != undefined) {
                    return params.data.games.length;
                }
            },
            // valueSetter: (params: ValueSetterParams) => {
            //     params.data.games = params.newValue != null ? params.newValue.length : 0;
            //     return true;
            // },
            cellEditor: GameEditor,
        },
        {
            headerName: "Admin code",
            field: "adminPinCode",
            width: 150,
            editable: true,
        },
        {
            headerName: "Reg code",
            field: "registrationCode",
            width: 150,
            editable: true,
        },
        {
            headerName: "Credit Enabled",
            field: "creditSystem",
            width: 80,
            editable: true,
            cellRenderer: "checkbox",
        },
        {
            headerName: "Cloud first day",
            field: "office.cloudDate.firstDay",
            width: 80,
            editable: true,
            valueGetter: (params: ValueGetterParams) => {
                const v = params.data.office.cloudDate.firstDay as string;
                if (v.startsWith("0001")) {
                    return "";
                }
                return v.substring(0, 10);
            },
            hide: true,
        },
        {
            headerName: "Cloud last day",
            field: "office.cloudDate.lastDay",
            width: 80,
            editable: true,
            valueGetter: (params: ValueGetterParams) => {
                const v = params.data.office.cloudDate.lastDay as string;
                if (v.startsWith("0001")) {
                    return "";
                }
                return v.substring(0, 10);
            },
            hide: true,
        },
        {
            headerName: "Time Budget (h)",
            field: "timeBudget",
            width: 120,
            editable: true,
            valueGetter: (params: ValueGetterParams) => {
                if (params.data.timeBudget != undefined) {
                    return secToHour(params.data.timeBudget);
                }
            },
            valueSetter: (params: ValueSetterParams) => {
                if (params.oldValue != params.newValue) {
                    params.data.timeBudget = Number(params.newValue) * 3600;
                    return true;
                }
                return false;
            },
        },
        {
            headerName: "Languages",
            field: "languages",
            width: 150,
            editable: true,
            valueGetter: (params: ValueGetterParams) => {
                if (params.data.languages != undefined) {
                    return params.data.languages.join(",");
                }
            },
            valueSetter: (params: ValueSetterParams) => {
                if (params.oldValue != params.newValue) {
                    params.data.languages = params.newValue.split(",");
                    return true;
                }
                return false;
            },
            hide: true,
        },

        {
            headerName: "Comment",
            field: "comment",
            width: 300,
            cellEditorParams: {
                maxLength: 1000,
            },
            cellStyle: {
                "white-space": "normal",
            },
            cellEditor: "agLargeTextCellEditor",
            suppressSizeToFit: true,
            editable: true,
            hide: true,
        },
        {
            headerName: "Puzzle Message",
            field: "puzzleWelcomeMessage",
            width: 130,
            valueGetter: (params: ValueGetterParams) => {
                if (params.data.puzzleWelcomeMessage != undefined) {
                    const keys = Object.keys(params.data.puzzleWelcomeMessage);
                    return keys.join(",");
                }
            },
            cellEditor: LangEditor,
            cellEditorPopup: true,
            editable: true,
            hide: true,
        },
        {
            headerName: "Actions",
            field: "action",
            width: 120,
            cellRenderer: Actions,
            suppressMenu: true,
            pinned: "right",
            suppressSizeToFit: true,
        },
    ];
}
