<template>
    <q-card>
        <q-card-section class="row items-center">
            <q-avatar icon="signal_wifi_off" color="primary" text-color="white" />
            <span class="q-ml-sm">Are you sure to delete this row?</span>
        </q-card-section>

        <q-card-actions align="right">
            <q-btn flat label="Cancel" color="primary" v-close-popup />
            <q-btn flat label="Delete" color="primary" @click="onClick" v-close-popup />
        </q-card-actions>
    </q-card>
</template>

<script setup lang="ts">
import { IPartner } from "@/pages/Partner/model";

const emit = defineEmits(["dialogEvent"]);

const props = defineProps<{
    partner: IPartner;
}>();

function onClick() {
    emit("dialogEvent", { action: "delete" });
}
</script>
